import { useState, useEffect } from 'react';
import { getArchitecureAreas } from './getArchitecureAreas';

export type TCONFData = {
  architectureArea: string[] | undefined;
  responseError: unknown;
  loading: Boolean;
};

export const useConfArchArea = (): TCONFData => {
  const [confArchArea, setConfArchArea] = useState<string[]>();
  const [responseError, setResponseError] = useState<unknown>();
  const [loading, setLoading] = useState<boolean>(false);
  const getConfArchArea = async () => {
    setLoading(true);
    try {
      const items = getArchitecureAreas();
      // @ts-ignore
      const ArchAreaEntities: string[] = items?.architectureAreas?.map(
        entity => entity,
      );
      setConfArchArea(ArchAreaEntities);
    } catch (error) {
      setResponseError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getConfArchArea();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { architectureArea: confArchArea, responseError, loading };
};
