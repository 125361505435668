import { createPlugin } from '@backstage/core-plugin-api';

import {
  createTechDocsAddonExtension,
  TechDocsAddonLocations,
} from '@backstage/plugin-techdocs-react';

import { useEffect } from 'react';
import { useShadowRootElements } from '@backstage/plugin-techdocs-react';

const TechdocsStylingComponent = () => {
  const links = useShadowRootElements<HTMLAnchorElement>(['a']);

  useEffect(() => {
    links.forEach(l => {
      l.style.textDecoration = 'underline';
    });
  }, [links]);

  return null;
};

export const techdocsstylePlugin = createPlugin({
  id: 'techdocsstyle',
});

export const TechdocsStyling = techdocsstylePlugin.provide(
  createTechDocsAddonExtension({
    name: 'TechdocsStyling',
    location: TechDocsAddonLocations.Content,
    component: TechdocsStylingComponent,
  }),
);
