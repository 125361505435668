import * as React from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { FieldValidation } from '@rjsf/utils';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import {
  createScaffolderFieldExtension,
  FieldExtensionComponentProps,
} from '@backstage/plugin-scaffolder-react';
import {
  OwnerFieldOutputT,
  zOwnerObject,
  toOwnerFieldOutput,
} from '@briljera/common';
import { ToggleableOwnerObjectTable } from './ToggleableOwnerObjectTable';
import { EditableMenuItem } from './EditableMenuItem';
import { AddNewMenuItem } from './AddNewMenuItem';
import {
  TO_BE_CREATED_OBJECT_ID,
  mkOwnerFieldOutput,
} from './toOwnerFieldOutput';
import { useFrontendConfig, sasgApiRef } from '@internal/common-frontend';
import { LinearProgressIndicator } from '../../Common/LinearProgressIndicator';

type MenuItemData =
  | { type: 'FromSasgAPI'; value: OwnerFieldOutputT }
  | { type: 'UserAdded'; value: OwnerFieldOutputT }
  | { type: 'AddNew' };

const mkTeamNameTxt = (ownerObject?: OwnerFieldOutputT): string =>
  ownerObject
    ? `"${ownerObject.admin.expectedGithubTeamName}", "${ownerObject.contrib.expectedGithubTeamName}" and "${ownerObject.read.expectedGithubTeamName}"`
    : '';

const OwnerField = (
  ownerField: FieldExtensionComponentProps<
    | {
        owner: string;
        admin: {
          securityGroupObjectId: string;
          securityGroupName: string;
          expectedGithubTeamName: string;
        };
        contrib: {
          securityGroupObjectId: string;
          securityGroupName: string;
          expectedGithubTeamName: string;
        };
        read: {
          securityGroupObjectId: string;
          securityGroupName: string;
          expectedGithubTeamName: string;
        };
        guest?:
          | {
              securityGroupObjectId: string;
              securityGroupName: string;
              expectedGithubTeamName: string;
            }
          | undefined;
      }
    | undefined
  >,
) => {
  const config = useFrontendConfig();
  const baseUrl: string = config.getString('app.baseUrl');
  const dpzApiClient = useApi(sasgApiRef);

  const [isDropDownOpen, closeDropDown] = React.useState(false);

  const {
    value: sasgAPIResponse,
    error,
    loading,
  } = useAsync(dpzApiClient.fetchOwnerObject);

  const [userAddedTeam, setUserAddedTeam] = React.useState<
    OwnerFieldOutputT | undefined
  >(undefined);

  React.useEffect(() => {
    if (
      ownerField.formData?.admin?.securityGroupObjectId ===
      TO_BE_CREATED_OBJECT_ID
    )
      setUserAddedTeam(mkOwnerFieldOutput(ownerField.formData.owner));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading)
    return <LinearProgressIndicator title="Loading data, please wait ..." />;

  if (sasgAPIResponse?.length === 0) {
    return (
      <Typography variant="body1" color="secondary">
        Unfortunately, you don't have a GitHub team at the moment. Kindly
        request{' '}
        <Link href={`${baseUrl}/samspela-info/requestTeam`}>access</Link>.
      </Typography>
    );
  }

  if (error || !sasgAPIResponse)
    return (
      <Typography variant="body1" color="error">
        Data loading error: {error?.message}
      </Typography>
    );

  const menuItemDataList: Array<MenuItemData> = [
    ...sasgAPIResponse.map(
      value =>
        ({
          type: 'FromSasgAPI',
          value,
        } as const),
    ),
    userAddedTeam
      ? {
          type: 'UserAdded',
          value: userAddedTeam,
        }
      : { type: 'AddNew' },
  ];

  return (
    <FormControl
      margin="normal"
      required={ownerField.required}
      error={ownerField.rawErrors?.length > 0 && !ownerField.formData}
    >
      <InputLabel htmlFor="ownerFieldID">Select GitHub Team</InputLabel>
      <Select
        id="ownerFieldID"
        value={ownerField.formData?.owner || ''}
        onChange={e => {
          ownerField.onChange(
            toOwnerFieldOutput(
              menuItemDataList
                .filter(
                  (
                    item: MenuItemData,
                  ): item is Exclude<MenuItemData, { type: 'AddNew' }> =>
                    item.type !== 'AddNew',
                )
                .map(item => item.value),
              e.target?.value,
            ),
          );
          closeDropDown(false);
        }}
        open={isDropDownOpen}
        onOpen={() => closeDropDown(true)}
        onClose={() => closeDropDown(false)}
      >
        {menuItemDataList.map(item => {
          switch (item.type) {
            case 'FromSasgAPI':
              return (
                <MenuItem value={item.value.owner} key={item.value.owner}>
                  {item.value.owner}
                </MenuItem>
              );
            case 'UserAdded':
              return (
                <EditableMenuItem
                  value={item.value.owner}
                  key={item.value.owner}
                  ownerName={item.value.owner}
                  setFormData={v => {
                    setUserAddedTeam(v);
                    ownerField.onChange(v);
                    closeDropDown(false);
                  }}
                >
                  {item.value.owner}
                </EditableMenuItem>
              );
            case 'AddNew':
              return (
                <AddNewMenuItem
                  value="ADD_NEW_OWNER"
                  key="ADD_NEW_OWNER"
                  setFormData={v => {
                    setUserAddedTeam(v);
                    ownerField.onChange(v);
                    closeDropDown(false);
                  }}
                />
              );

            default:
              throw Error(
                'Switch not exchausive error. To be replaced by the satisfies keyword in TypeScript >=4.9',
              );
          }
        })}
      </Select>
      {ownerField.formData?.owner && (
        <FormHelperText component="span">
          Based on your selection, these existing GitHub teams will be used:
          <Typography style={{ fontWeight: 'bold' }} variant="body2">
            {mkTeamNameTxt(ownerField.formData)}
          </Typography>
          <ToggleableOwnerObjectTable ownerObject={ownerField.formData} />
        </FormHelperText>
      )}
    </FormControl>
  );
};

export const OwnerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: OwnerField,
    name: 'InterIKEABriljeraOwner',
    validation: (
      value: OwnerFieldOutputT | undefined,
      validation: FieldValidation,
    ) => {
      try {
        zOwnerObject.parse(value);
      } catch (e) {
        validation.addError(JSON.stringify(value));
      }
    },
  }),
);
