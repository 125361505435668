import { v4 as uuidv4 } from 'uuid';
import { IdentityApi } from '@backstage/plugin-auth-node';
import { Request } from 'express';
import { z } from 'zod';
import jwt from 'jsonwebtoken';
import { Config } from '@backstage/config';
import { decodeAndDecrypt } from '@briljera/common';

const fetchSessionId = (idToken: string): string => {
  const tokenData = z.object({ sid: z.string() }).parse(jwt.decode(idToken));
  return tokenData.sid;
};

export const fetchTeam = (idToken: string): string[] => {
  const tokenData = z
    .object({ roles: z.array(z.string()) })
    .parse(jwt.decode(idToken));
  return tokenData.roles;
};
// This method will extract the backstage token from the request and can be used for all the backend plugins.
export const extractBSToken = (
  identity: IdentityApi,
  request: Request,
): Promise<string> =>
  identity.getIdentity({ request }).then(id => z.string().parse(id?.token));

// This method will parse the token and return the oid and aut,so can be used for all the token parsing from backend plugins.
export const parseBSToken = (
  rawToken: string,
  config: Config,
): { oid: string; aut: string } => {
  const tokenData = z
    .object({ oid: z.string(), aut: z.string() })
    .parse(jwt.decode(rawToken));
  const decryptionKey = config.getString('dpz.encryptionKey');
  const decryptionKeyBuffer = Buffer.from(decryptionKey, 'base64');
  const decryptedAut = decodeAndDecrypt(tokenData.aut, decryptionKeyBuffer);
  const decryptedOid = decodeAndDecrypt(tokenData.oid, decryptionKeyBuffer);
  return {
    oid: decryptedOid,
    aut: decryptedAut,
  };
};
// This method will generate the request and session id irrespective the token is decrypted or encrypted
export const generateRequestAndSessionId = async (
  config: Config,
  decryptedToken?: string,
  rawToken?: string,
): Promise<{ reqId: string; sessId: string }> => {
  const requestId = uuidv4();
  let sessionId: string = 'defaultSessionId';
  if (decryptedToken) {
    sessionId = fetchSessionId(decryptedToken);
  } else if (rawToken) {
    const { aut: authIDToken } = parseBSToken(rawToken, config);
    sessionId = fetchSessionId(authIDToken);
  }
  return { reqId: requestId, sessId: sessionId };
};
