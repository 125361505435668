import { OwnerFieldOutputT } from '@briljera/common';
import { MenuItem, MenuItemProps } from '@material-ui/core';
import Button from '@ingka/button';

import * as React from 'react';
import { OwnerNameInput } from './OwnerNameInput';
import { mkOwnerFieldOutput } from './toOwnerFieldOutput';

export const EditableMenuItem = React.forwardRef<
  HTMLLIElement,
  {
    ownerName: string;
    setFormData: (p: OwnerFieldOutputT) => void;
  } & MenuItemProps
>(({ setFormData, button, ownerName, ...props }, ref) => {
  const [isSelected, setIsSelected] = React.useState(false);
  return (
    <MenuItem
      {...props}
      ref={ref}
      onClick={e =>
        isSelected
          ? e.stopPropagation()
          : setFormData(mkOwnerFieldOutput(ownerName))
      }
    >
      {isSelected ? (
        <OwnerNameInput
          ownerName={ownerName}
          updateOwnername={name => setFormData(mkOwnerFieldOutput(name))}
        />
      ) : (
        <>
          {ownerName}
          <Button
            small
            type="tertiary"
            onClick={e => {
              setIsSelected(true);
              e.stopPropagation();
            }}
          >
            Edit
          </Button>
        </>
      )}
    </MenuItem>
  );
});
