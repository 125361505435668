import { Config } from '@backstage/config';
import {
  AnalyticsApi,
  ErrorApi,
  IdentityApi,
  AnalyticsEvent,
} from '@backstage/core-plugin-api';
import SplunkRum from '@splunk/otel-web';
import { trace } from '@opentelemetry/api';

export type SplunkOptions = {
  realm: 'us0' | 'eu0';
  deploymentEnvironment: string;
  rumAccessToken: string;
  applicationName: string;
  version: string;
};

/**
 * Splunk API provider for the Backstage Analytics API.
 * @public
 */
export class Splunk implements AnalyticsApi {
  private readonly tracer;

  private constructor(
    options: SplunkOptions,
    identityApi?: IdentityApi,
    errorApi?: ErrorApi,
    userIdTransform?: 'sha-256' | ((userEntityRef: string) => Promise<string>),
  ) {
    let globalAttributes:
      | { 'enduser.id': string; 'enduser.role': string }
      | undefined;

    if (identityApi) {
      identityApi.getBackstageIdentity().then(identity => {
        if (typeof userIdTransform === 'function') {
          userIdTransform(identity.userEntityRef).then(userId => {
            globalAttributes = {
              'enduser.id': userId,
              'enduser.role': identity.type,
            };
          });
        } else {
          this.hash(identity.userEntityRef).then(userId => {
            globalAttributes = {
              'enduser.id': userId,
              'enduser.role': identity.type,
            };
          });
        }
      });
    }
    try {
      SplunkRum.init({
        ...options,
        instrumentations: {
          document: true,
          errors: true,
          fetch: true,
          interactions: true,
          longtask: true,
          visibility: true,
          connectivity: true,
          postload: true,
          socketio: true,
          websocket: true,
          webvitals: true,
          xhr: true,
        },
        ...(globalAttributes ? { globalAttributes } : {}),
      });
      this.tracer = trace.getTracer('appModuleLoader');
    } catch (e) {
      if (errorApi) {
        errorApi.post({
          name: 'splunk',
          message: 'Error initializing Splunk integration',
        });
      }
    }
  }

  static getRealm(realmString: string | undefined): 'us0' | 'eu0' {
    return realmString === 'us0' || realmString === 'eu0' ? realmString : 'eu0';
  }

  static fromConfig(
    config: Config,
    options: {
      identityApi?: IdentityApi;
      errorApi?: ErrorApi;
      userIdTransform?:
        | 'sha-256'
        | ((userEntityRef: string) => Promise<string>);
    },
  ) {
    const splunkConfig = config.getOptionalConfig('app.analytics.splunk');

    if (!splunkConfig) {
      throw new Error('Problem retrieving Splunk configuration.');
    }

    const splunkOptions: SplunkOptions = {
      realm: Splunk.getRealm(splunkConfig.getOptionalString('realm')),
      deploymentEnvironment:
        splunkConfig.getOptionalString('deploymentEnvironment') || '',
      rumAccessToken: splunkConfig.getOptionalString('rumAccessToken') || '',
      applicationName: splunkConfig.getOptionalString('applicationName') || '',
      version: splunkConfig.getOptionalString('version') || '',
    };

    return new Splunk(
      splunkOptions,
      options.identityApi,
      options.errorApi,
      options.userIdTransform,
    );
  }

  captureEvent(event: AnalyticsEvent) {
    const { context, action, subject, value, attributes } = event;

    if (!this.tracer) {
      return;
    }

    if (action === 'navigate' && context.extension === 'App') {
      const span = this.tracer.startSpan('navigation', {
        attributes: {
          'workflow.name': context.pluginId,
        },
      });
      span.end();
      return;
    }

    if (action === 'team' && context.extension === 'App') {
      if (attributes?.role) {
        const span = this.tracer.startSpan('team', {
          attributes: {
            'workflow.name': attributes.role,
          },
        });
        span.end();
      }
      return;
    }

    if (action === 'login' && context.extension === 'App') {
      const span = this.tracer.startSpan('login', {
        attributes: {
          'workflow.name': action,
        },
      });
      span.end();
      return;
    }

    if (
      action === 'create' &&
      context.extension === 'ScaffolderPage' &&
      typeof context?.entityRef === 'string'
    ) {
      const span = this.tracer.startSpan('template', {
        attributes: {
          'workflow.name': this.cleanTemplateName(context.entityRef),
        },
      });
      span.end();
      return;
    }

    const customAttributes: {
      [x: string]: string | number | boolean | undefined;
    } = {};
    if (value) {
      customAttributes.value = value;
    }
    Object.keys(context).forEach(key => {
      if (context[key]) {
        customAttributes[`context.${key}`] = context[key];
      }
    });
    if (attributes) {
      Object.keys(attributes).forEach(key => {
        customAttributes[`attributes.${key}`] = attributes[key];
      });
    }

    const span = this.tracer.startSpan(action, {
      attributes: {
        'workflow.name': action,
      },
    });
    span.setAttribute('subject', subject);
    span.setAttributes(customAttributes);
    span.end();
  }

  private cleanTemplateName = (value: string) =>
    value.replace('template:default/', '');

  /**
   * Simple hash function; relies on web cryptography + the sha-256 algorithm.
   * @param value value to be hashed
   */
  private async hash(value: string): Promise<string> {
    const digest = await window.crypto.subtle.digest(
      'sha-256',
      new TextEncoder().encode(value),
    );
    const hashArray = Array.from(new Uint8Array(digest));
    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  }
}
