import * as React from 'react';
import { styled, Typography } from '@material-ui/core';
import { Progress } from '@backstage/core-components';

const VerticalStack = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
});

export const LinearProgressIndicator = (props: { title: string }) => (
  <VerticalStack>
    <Progress />
    <Typography variant="body1" color="primary">
      {props.title}
    </Typography>
  </VerticalStack>
);
