import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useApi, githubAuthApiRef } from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
  CardActionArea,
  Avatar,
  makeStyles,
} from '@material-ui/core';
import { Entity } from '@backstage/catalog-model';
import { Progress } from '@backstage/core-components';

import Button from '@ingka/button';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/button/dist/style.css';
import '@ingka/focus/dist/style.css';

const useStyles = makeStyles({
  fontFix: {
    fontFamily: 'Noto IKEA',
  },
});

interface PullRequest {
  id: number;
  title: string;
  number: number;
  user: {
    login: string;
    avatar_url: string;
  };
  created_at: string;
  state: string;
  html_url: string;
  reviewStatus?: 'approved' | 'under_review' | 'none' | string;
}

interface Review {
  state: string;
}

const fetchWithToken = async (url: string, token: string) => {
  const response = await fetch(url, {
    headers: { Authorization: `token ${token}` },
  });
  if (!response.ok) {
    throw new Error(`Error fetching data: ${response.statusText}`);
  }
  return response.json();
};

const getRepoDetails = (url: string | undefined) => {
  if (!url) {
    throw new Error('GitHub repository URL not found in entity annotations.');
  }

  const urlMatch = url.match(/https:\/\/github\.com\/([^\/]+)\/([^\/]+)/);
  if (!urlMatch) {
    throw new Error('Invalid GitHub repository URL format.');
  }

  return { owner: urlMatch[1], repo: urlMatch[2] };
};

const ComponentPullRequest: React.FC = () => {
  const classes = useStyles();
  const { entity } = useEntity<Entity>();
  const githubAuth = useApi(githubAuthApiRef);
  const [pullRequests, setPullRequests] = useState<PullRequest[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filter, setFilter] = useState<'under_review' | 'approved'>(
    'under_review',
  );

  const fetchPullRequests = useCallback(async () => {
    try {
      const token = await githubAuth.getAccessToken(['repo:status']);
      const { owner, repo } = getRepoDetails(
        entity.metadata.annotations?.['backstage.io/managed-by-location'],
      );

      const pullsUrl = `https://api.github.com/repos/${owner}/${repo}/pulls?state=all`;
      const pullsData: PullRequest[] = await fetchWithToken(pullsUrl, token);

      const filteredPullRequests = await Promise.all(
        pullsData.map(async pr => {
          const reviewsUrl = `https://api.github.com/repos/${owner}/${repo}/pulls/${pr.number}/reviews`;
          const reviews: Review[] = await fetchWithToken(reviewsUrl, token);

          const approvedReview = reviews.find(
            review => review.state === 'APPROVED',
          );
          const underReview = reviews.find(
            review =>
              review.state === 'CHANGES_REQUESTED' ||
              review.state === 'COMMENTED',
          );

          let reviewStatus = 'none';
          if (approvedReview) {
            reviewStatus = 'approved';
          } else if (underReview) {
            reviewStatus = 'under_review';
          }

          return {
            ...pr,
            reviewStatus,
          };
        }),
      );

      setPullRequests(filteredPullRequests);
      setLoading(false);
    } catch (err) {
      const errorMessage = (err as Error).message;
      setError(errorMessage);
      setLoading(false);
    }
  }, [entity, githubAuth]);

  useEffect(() => {
    fetchPullRequests();
  }, [fetchPullRequests]);

  const handleFilterChange = (newFilter: 'under_review' | 'approved') => {
    setFilter(newFilter);
  };

  const filteredPullRequests = useMemo(
    () =>
      pullRequests.filter(pr => {
        if (filter === 'approved') {
          return pr.reviewStatus === 'approved' && pr.state === 'open';
        }
        if (filter === 'under_review') {
          return (
            (pr.reviewStatus === 'under_review' || pr.state === 'open') &&
            pr.reviewStatus !== 'approved'
          );
        }
        return pr.state === 'open';
      }),
    [pullRequests, filter],
  );

  if (loading) {
    return (
      <>
        <Progress />
        <Typography variant="body1" color="primary">
          Loadin Pull Requests...
        </Typography>
      </>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <div>
      <div>
        <Typography variant="h4">Pull Requests</Typography>
        <br />
      </div>
      <div style={{ marginBottom: '20px' }}>
        <Button
          className={classes.fontFix}
          type={filter === 'under_review' ? 'primary' : 'secondary'}
          onClick={() => handleFilterChange('under_review')}
          text="Under Review"
          style={{ marginRight: '10px' }}
        />
        <Button
          className={classes.fontFix}
          type={filter === 'approved' ? 'primary' : 'secondary'}
          onClick={() => handleFilterChange('approved')}
          text="Approved"
          style={{ marginRight: '10px' }}
        />
      </div>
      <Grid container spacing={3}>
        {filteredPullRequests.map(pr => (
          <Grid item xs={12} md={4} key={pr.id}>
            <Card style={{ height: '100%' }}>
              <CardActionArea
                href={pr.html_url}
                target="_blank"
                style={{ height: '100%' }}
              >
                <CardHeader
                  avatar={
                    <Avatar
                      alt={pr.user.login}
                      src={`https://avatars.githubusercontent.com/${pr.user.login}`}
                    />
                  }
                  title={
                    <Typography variant="subtitle1">{pr.title}</Typography>
                  }
                  subheader={`#${pr.number} by ${pr.user.login}`}
                />
                <CardContent>
                  <Typography variant="caption" color="textSecondary">
                    {new Date(pr.created_at).toLocaleString()}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ComponentPullRequest;
