import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const apimPlugin = createPlugin({
  id: 'apim',
  routes: {
    root: rootRouteRef,
  },
});

export const ApimPage = apimPlugin.provide(
  createRoutableExtension({
    name: 'ApimPage',
    component: () =>
      import('./components/LandingPage').then(m => m.LandingPage),
    mountPoint: rootRouteRef,
  }),
);
