import {
  createApiFactory,
  DiscoveryApi,
  FetchApi,
  createApiRef,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import {
  SasgAPIResponseT,
  TeamNameValidationResult,
  zErrorResponseBody,
  zSasgAPIResponse,
  zTeamNameValidationResult,
} from '@briljera/common';
import { z } from 'zod';

const zValidDpzApiBaseUrl = z
  .string()
  .startsWith('http', 'Invalid dpz-apis baseUrl');
const mkSasgApiClient = (deps: {
  discoveryApi: DiscoveryApi;
  fetchApi: FetchApi;
}) => {
  const fetchDpzApiUrl = (): Promise<string> =>
    deps.discoveryApi.getBaseUrl('dpz-apis').then(zValidDpzApiBaseUrl.parse);

  return {
    fetchOwnerObject: (): Promise<SasgAPIResponseT> =>
      fetchDpzApiUrl().then(dpzApiUrl =>
        deps.fetchApi
          .fetch(`${dpzApiUrl}/owner-object`)
          .then(async response => {
            const jsonData = await response.json();
            if (response.ok) return jsonData;

            const respError = zErrorResponseBody.parse(jsonData);
            throw new Error(respError.error.message);
          })
          .then(zSasgAPIResponse.parse),
      ),

    validateTeamName: (teamName: string): Promise<TeamNameValidationResult> =>
      fetchDpzApiUrl().then(dpzApiUrl =>
        deps.fetchApi
          .fetch(`${dpzApiUrl}/validate-team-name/${teamName}`)
          .then(response => response.json())
          .then(zTeamNameValidationResult.parse),
      ),
  };
};

export const sasgApiRef = createApiRef<ReturnType<typeof mkSasgApiClient>>({
  id: 'plugin.sasg.api',
});

export const sasgApiFactory = createApiFactory({
  api: sasgApiRef,
  deps: {
    discoveryApi: discoveryApiRef,
    fetchApi: fetchApiRef,
  },
  factory: mkSasgApiClient,
});
