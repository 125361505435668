import {
  DiscoveryApi,
  FetchApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { AwscoreApi } from './types';
import { Team, Account } from '@internal/plugin-awscore-common';

/**
 * Options for creating an AwscoreClient.
 *
 * @public
 */
export interface AwscoreClientOptions {
  discoveryApi: DiscoveryApi;
  fetchApi: FetchApi;
  identityApi: IdentityApi;
}

/**
 * An implementation of the AwscoreApi that communicates with the Awscore backend plugin.
 *
 * @public
 */
export class AwscoreClient implements AwscoreApi {
  private readonly fetchApi: FetchApi;
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: AwscoreClientOptions) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
    this.identityApi = options.identityApi;
  }

  async listAccounts(): Promise<Response> {
    const baseUrl = await this.discoveryApi.getBaseUrl('awscore');
    return await this.fetchApi.fetch(`${baseUrl}/accounts`);
  }

  async getAccount(id: string): Promise<Response> {
    const baseUrl = await this.discoveryApi.getBaseUrl('awscore');
    return await this.fetchApi.fetch(`${baseUrl}/accounts/${id}`);
  }

  async deleteAccount(id: string): Promise<Response> {
    const baseUrl = await this.discoveryApi.getBaseUrl('awscore');
    return await this.fetchApi.fetch(`${baseUrl}/accounts/${id}`, {
      method: 'DELETE',
    });
  }

  async updateAccount(body: Account): Promise<Response> {
    const baseUrl = await this.discoveryApi.getBaseUrl('awscore');
    return await this.fetchApi.fetch(`${baseUrl}/accounts/${body?.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
  }

  async createAccount(account: Account): Promise<Response> {
    const baseUrl = await this.discoveryApi.getBaseUrl('awscore');
    return await this.fetchApi.fetch(`${baseUrl}/accounts`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(account),
    });
  }

  async listTeams(): Promise<Response> {
    const baseUrl = await this.discoveryApi.getBaseUrl('awscore');
    const res = await this.fetchApi.fetch(`${baseUrl}/teams`);
    return res;
  }

  async getTeam(id: string): Promise<Response> {
    const baseUrl = await this.discoveryApi.getBaseUrl('awscore');
    return await this.fetchApi.fetch(`${baseUrl}/teams/${id}`);
  }

  async deleteTeam(id: string): Promise<Response> {
    const baseUrl = await this.discoveryApi.getBaseUrl('awscore');
    return await this.fetchApi.fetch(`${baseUrl}/teams/${id}`, {
      method: 'DELETE',
    });
  }

  async updateTeam(body: Team): Promise<Response> {
    const baseUrl = await this.discoveryApi.getBaseUrl('awscore');
    return await this.fetchApi.fetch(`${baseUrl}/teams/${body?.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
  }

  async createTeam(team: Team): Promise<Response> {
    const baseUrl = await this.discoveryApi.getBaseUrl('awscore');
    return await this.fetchApi.fetch(`${baseUrl}/teams`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(team),
    });
  }

  async getIdentity(): Promise<string> {
    const profile = await this.identityApi.getProfileInfo();

    return profile?.email || '';
  }
}
