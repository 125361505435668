import React, { useState, useEffect } from 'react';
import Hyperlink from '@ingka/hyperlink';
import '@ingka/hyperlink/dist/style.css';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Tooltip from '@material-ui/core/Tooltip';
import { getApiLink } from './utils';
import { Entity } from '@backstage/catalog-model';
import { Gauge } from '@backstage/core-components';
import { microsoftAuthApiRef, useApi } from '@backstage/core-plugin-api';
import { fetchTeam } from '@briljera/common';
import InfoIcon from '@mui/icons-material/Info';

const useStyles = makeStyles({
  table: {
    width: '100%',
    tableLayout: 'auto',
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  tableCell: {
    padding: '6px 10px 10px 10px',
    align: 'center',
  },
  container: {
    width: '100%',
    overflow: 'hidden',
  },
  gaugeWrapper: {
    width: '174px',
    height: '140px',
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoIcon: {
    width: '174px',
    height: '140px',
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

interface ApiTableProps {
  columns: { title: string; width: string }[]; // You can specify a more specific type for "columns"
  apis: Entity[] | undefined; // You can specify a more specific type for "apis"
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>; // This assumes "setPage" is a state updater function
  loading: boolean;
}

type JsonData = { [key: string]: JsonData } | string | number | boolean | null;

const JsonRenderer = React.memo(({ data }: { data: JsonData }) => {
  if (typeof data === 'object' && data !== null) {
    return (
      <div style={{ marginLeft: 20 }}>
        {Object.keys(data)
          .filter(
            key => key !== 'designHealthScore' && key !== 'numberOfViolations',
          )
          .map(key => (
            <div key={key}>
              <strong>{key}: </strong>
              <JsonRenderer data={data[key]} />
            </div>
          ))}
      </div>
    );
  }
  return <span>{String(data)}</span>;
});

export const ApiTable = ({
  columns,
  apis,
  page,
  setPage,
  loading,
}: ApiTableProps) => {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const tabletHeight = useMediaQuery('(min-height:900px)');
  const laptopHeight = useMediaQuery('(min-height:1050px)');
  const desktopHeight = useMediaQuery('(min-height:1260px)');
  const bigScreenHeight = useMediaQuery('(min-height:1450px)');
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Entity | null>(null);
  const [, setToken] = useState<string | null>(null);
  const [teams, setTeams] = useState<string[]>([]);
  const microsoftAuth = useApi(microsoftAuthApiRef);

  useEffect(() => {
    const fetchToken = async () => {
      if (process.env.NODE_ENV !== 'development') {
        const idToken = await microsoftAuth.getIdToken();
        setToken(idToken);
        setTeams(fetchTeam(idToken));
      } else {
        setTeams(['dp.apim.team']);
      }
    };

    fetchToken();
  }, [microsoftAuth]);

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: React.SetStateAction<number>,
  ) => {
    setPage(newPage);
  };

  const calTableHeight = () => {
    if (bigScreenHeight) {
      return '66vh';
    }
    if (desktopHeight) {
      return '63vh';
    }
    if (laptopHeight) {
      return '58vh';
    }
    if (tabletHeight) {
      return '53vh';
    }
    return '50vh';
  };

  const tableMaxHeight = calTableHeight();

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, (apis?.length || 0) - page * rowsPerPage);

  const handleChangeRowsPerPage = (event: { target: { value: string } }) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = (row: Entity) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  return (
    <Paper className={classes.container}>
      <TableContainer style={{ maxHeight: tableMaxHeight }}>
        <Table className={classes.table}>
          {loading ? (
            <LinearProgress />
          ) : (
            <>
              <TableHead>
                <TableRow>
                  {columns?.map(column => (
                    <TableCell
                      key={column.title}
                      align="center"
                      className={`${classes.tableCell}`}
                      style={{ width: column.width }}
                    >
                      {column.title}{' '}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {apis
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map(row => {
                    const healthScore = (() => {
                      try {
                        const data = JSON.parse(
                          row?.metadata?.annotations?.healthScore || '{}',
                        );
                        return data?.designHealthScore || 0;
                      } catch (e) {
                        return 0;
                      }
                    })();

                    return (
                      <TableRow
                        key={
                          row?.metadata?.name +
                          row?.metadata?.labels?.version +
                          row?.spec?.environment
                        }
                        className={classes.tableCell}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tableCell}
                          align="center"
                        >
                          <Tooltip title={row.metadata.title || ''}>
                            <Hyperlink url={`/${getApiLink(row).link}`}>
                              <Typography>
                                {' '}
                                {row.metadata.title?.toLocaleLowerCase()}
                              </Typography>
                            </Hyperlink>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tableCell}
                          align="center"
                        >
                          {row?.metadata?.labels?.version}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tableCell}
                          align="center"
                        >
                          {row?.spec?.environment
                            ? row?.spec?.environment.toString()
                            : ''}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          {row?.metadata?.labels?.type}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {row?.spec?.lifecycle
                            ? row?.spec?.lifecycle.toString()
                            : ''}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {row?.metadata?.labels?.architectureArea}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tableCell}
                          align="left"
                        >
                          {row?.spec?.owner &&
                          teams.some(team => {
                            const owner =
                              typeof row?.spec?.owner === 'string'
                                ? row?.spec?.owner.replace('group:default/', '')
                                : '';
                            return owner.includes(team);
                          }) ? (
                            <div
                              className={classes.gaugeWrapper}
                              onClick={() => {
                                if (healthScore !== 0 && healthScore !== 100) {
                                  handleClickOpen(row);
                                }
                              }}
                              tabIndex={0}
                              role="button"
                              onKeyDown={event => {
                                if (
                                  (event.key === 'Enter' ||
                                    event.key === ' ') &&
                                  healthScore !== 0 &&
                                  healthScore !== 100
                                ) {
                                  handleClickOpen(row);
                                }
                              }}
                              style={{
                                cursor:
                                  healthScore !== 0 && healthScore !== 100
                                    ? 'pointer'
                                    : 'default',
                              }}
                            >
                              <Gauge
                                value={
                                  healthScore / 100 === 1
                                    ? 0.99
                                    : healthScore / 100
                                }
                              />
                            </div>
                          ) : (
                            <div className={classes.infoIcon}>
                              <Tooltip title="Only API owners are allowed to view their API rating, in current version">
                                <InfoIcon> </InfoIcon>
                              </Tooltip>
                            </div>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 30 * emptyRows }}>
                    <TableCell colSpan={columns.length} />
                  </TableRow>
                )}
              </TableBody>
            </>
          )}
        </Table>
      </TableContainer>
      {loading || (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={apis?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Validation results</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <JsonRenderer
              data={JSON.parse(
                selectedRow?.metadata?.annotations?.healthScore || '{}',
              )}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};
