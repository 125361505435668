import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  analyticsApiRef,
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  errorApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { Splunk } from '@briljera/analytics-module-splunk';
import { sasgApiFactory } from '@internal/common-frontend';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory({
    api: analyticsApiRef,
    deps: {
      configApi: configApiRef,
      identityApi: identityApiRef,
      errorApi: errorApiRef,
    },
    factory: ({ configApi, identityApi, errorApi }) =>
      Splunk.fromConfig(configApi, { identityApi, errorApi }),
  }),
  sasgApiFactory,
];
