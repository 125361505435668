import { OwnerFieldOutputT } from '@briljera/common';
import { MenuItem, MenuItemProps } from '@material-ui/core';
import Button from '@ingka/button';
import * as React from 'react';
import { OwnerNameInput } from './OwnerNameInput';
import { mkOwnerFieldOutput } from './toOwnerFieldOutput';

export const AddNewMenuItem = React.forwardRef<
  HTMLLIElement,
  {
    setFormData: (p: OwnerFieldOutputT) => void;
  } & MenuItemProps
>(({ setFormData, button, ...props }, ref) => {
  const [isSelected, setIsSelected] = React.useState(false);
  return (
    <MenuItem
      {...props}
      ref={ref}
      onClick={e => {
        e.stopPropagation();
        setIsSelected(true);
      }}
    >
      {isSelected ? (
        <OwnerNameInput
          ownerName=""
          updateOwnername={name => setFormData(mkOwnerFieldOutput(name))}
        />
      ) : (
        <Button small>Add new</Button>
      )}
    </MenuItem>
  );
});
