import { Grid, TextField } from '@material-ui/core';
import * as React from 'react';
import { Autocomplete } from '@material-ui/lab';

export const ColumnFilter = (props: {
  columnFilterOption: ColumnFilterOption | undefined;
  setSelectedEnvironment: React.Dispatch<React.SetStateAction<string[]>>;
  tab: string;
}) => {
  const { columnFilterOption, setSelectedEnvironment, tab } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        {tab === 'openapi' ? (
          <Autocomplete
            multiple
            size="small"
            defaultValue={['prod']}
            options={columnFilterOption?.environment || ['def']}
            onChange={(_: React.ChangeEvent<{}>, newValue) => {
              setSelectedEnvironment(newValue);
            }}
            renderInput={params => (
              <TextField {...params} label="Environment" variant="outlined" />
            )}
          />
        ) : (
          <Autocomplete
            multiple
            size="small"
            options={columnFilterOption?.environment || ['def']}
            onChange={(_: React.ChangeEvent<{}>, newValue) => {
              setSelectedEnvironment(newValue);
            }}
            renderInput={params => (
              <TextField {...params} label="Environment" variant="outlined" />
            )}
          />
        )}
      </Grid>
    </Grid>
  );
};

export interface ColumnFilterOption {
  environment: Array<string>;
  architectureArea: Array<string | undefined>;
}
