/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { PropsWithChildren } from 'react';
import { Link, makeStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import CodeSharp from '@material-ui/icons/CodeSharp';
import ExtensionIcon from '@material-ui/icons/Extension';
import CloudIcon from '@material-ui/icons/Cloud';
import MapIcon from '@material-ui/icons/MyLocation';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import BuildIcon from '@material-ui/icons/Build';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarSubmenu,
  SidebarSubmenuItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  useSidebarOpenState,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { maintainersAccessPermission } from '@internal/plugin-maintainers-common';
import { usePermission } from '@backstage/plugin-permission-react';
import Ikealogo from '../Assets/brand-default-4e87d142.svg';
import Text from '@ingka/text';
import '@ingka/text/dist/style.css';
import '@ingka/avatar/dist/style.css';
import { awscoreAccessPermission } from '@internal/plugin-awscore-common';
import catalogIcon from '@ingka/ssr-icon/paths/document';
import SSRIcon from '@ingka/ssr-icon';

import '@ingka/svg-icon/dist/style.css';

const catalogIcon2 = () => {
  return <SSRIcon viewBox="0 0 24 24" paths={catalogIcon} />;
};

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
  logo: {
    margin: '25px',
    color: '#111',
    height: '30px',
  },
  title: {
    color: '#111',
    marginLeft: 8,
    fontWeight: 700,
    fontSize: '16px',
    display: 'block',
  },
  titleAlign: {
    fontWeight: 700,
    fontSize: '18px',
  },
});

export const SidebarLogo = (props: { open: string }) => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();
  return (
    <>
      <div data-testid="title" className={classes.root}>
        <Link underline="none" aria-label="Home">
          {isOpen || props.open === 'true' ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={Ikealogo} alt="Ikealogo" className={classes.logo} />
              <Text headingSize="s" className={classes.titleAlign}>
                <b>SAMSPELA</b>
              </Text>
            </div>
          ) : (
            <div className={classes.title}>
              <button className="avatar avatar--btn avatar--large avatar--primary avatar--text">
                <span className="avatar__text">S</span>
              </button>
            </div>
          )}
        </Link>
      </div>
    </>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const { allowed: canCreatePermission } = usePermission({
    permission: catalogEntityCreatePermission,
  });
  const { allowed: canAccessMaintainers } = usePermission({
    permission: maintainersAccessPermission,
  });
  const { allowed: canAccessAwscore } = usePermission({
    permission: awscoreAccessPermission,
  });

  return (
    <SidebarPage>
      <Sidebar>
        <SidebarLogo open="false" />
        <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
          <SidebarSearchModal />
        </SidebarGroup>
        <SidebarDivider />
        <SidebarGroup label="Menu" icon={<MenuIcon />}>
          <SidebarItem icon={HomeIcon} to="/dashboard" text="Dashboard" />
          <SidebarItem
            icon={catalogIcon2}
            to="catalog"
            text="Software Catalog"
          />
          <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
          <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
          {canCreatePermission && (
            <SidebarItem icon={CreateComponentIcon} to="create" text="Create" />
          )}
          {/* End global nav */}
          <SidebarDivider />
          <SidebarScrollWrapper>
            <SidebarItem icon={MapIcon} to="tech-radar" text="Tech Radar" />
          </SidebarScrollWrapper>
          <SidebarItem icon={CodeSharp} to="/apim" text="API Management" />
          {canAccessMaintainers && (
            <SidebarItem
              icon={BuildIcon}
              to="/maintainers"
              text="Maintainers"
            />
          )}
          {canAccessAwscore && (
            <SidebarItem icon={CloudIcon} to="#" text="CSM">
              <SidebarSubmenu title="Cloud Services Management">
                <SidebarSubmenuItem
                  title="AWS"
                  to="#"
                  icon={CloudIcon}
                  dropdownItems={[
                    {
                      title: 'Accounts',
                      to: '/awscore/accounts',
                    },
                    {
                      title: 'Teams',
                      to: '/awscore/teams',
                    },
                  ]}
                />
              </SidebarSubmenu>
            </SidebarItem>
          )}
        </SidebarGroup>
        <SidebarGroup>
          <SidebarItem icon={VpnKeyIcon} to="/samspela-info" text="SAMSPELA" />
        </SidebarGroup>
        <SidebarSpace />
        <SidebarDivider />
        <SidebarGroup
          label="Settings"
          icon={<UserSettingsSignInAvatar />}
          to="/settings"
        >
          <SidebarSettings />
        </SidebarGroup>
      </Sidebar>
      {children}
    </SidebarPage>
  );
};
