import {
  FormControl,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import Button from '@ingka/button';
import * as React from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { sasgApiRef } from '@internal/common-frontend';

export const OwnerNameInput = (props: {
  ownerName: string | undefined;
  updateOwnername: (ownerName: string) => void;
}) => {
  const [ownerName, setOwnerName] = React.useState<string>(
    props.ownerName || '',
  );

  const [validateResult, setValidationResult] = React.useState<
    | 'NotValidated'
    | 'Validating'
    | 'VALID_TEAM_NAME' // from TeamNameValidationResult
    | 'INVALID_TEAM_NAME' // from TeamNameValidationResult
    | 'Error'
  >('NotValidated');
  const dpzApiClient = useApi(sasgApiRef);

  return (
    <FormControl variant="standard" required fullWidth>
      <TextField
        label="New team name"
        error={
          validateResult === 'Error' || validateResult === 'INVALID_TEAM_NAME'
        }
        id="input-with-icon-adornment"
        onChange={e => {
          setOwnerName(e.target.value);
          e.stopPropagation();
        }}
        disabled={validateResult === 'Validating'}
        onKeyDown={e => e.stopPropagation()}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {validateResult === 'Validating' ? (
                <Typography color="textPrimary">
                  Validating, please wait...
                </Typography>
              ) : (
                <Button
                  type="tertiary"
                  onClick={e => {
                    e.stopPropagation();
                    setValidationResult('Validating');
                    dpzApiClient.validateTeamName(ownerName).then(r => {
                      setValidationResult(r.result);
                      if (r.result === 'VALID_TEAM_NAME')
                        props.updateOwnername(ownerName);
                    });
                  }}
                >
                  OK
                </Button>
              )}
            </InputAdornment>
          ),
        }}
        value={ownerName}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        helperText={
          validateResult === 'Error' || validateResult === 'INVALID_TEAM_NAME'
            ? `GitHub Teams and/or AAD Security groups with the name ${ownerName} already exist. Please use another name`
            : 'Unique name of the GitHub name(s) and the ADD security group(s)'
        }
      />
    </FormControl>
  );
};
