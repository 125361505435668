import * as React from 'react';
import { CompoundEntityRef } from '@backstage/catalog-model';
import { makeStyles } from '@material-ui/core';
import Modal, {
  Sheets,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@ingka/modal';
import arrowRight from '@ingka/ssr-icon/paths/arrow-right';
import SSRIcon from '@ingka/ssr-icon';
import Text from '@ingka/text';
import Table, { TableBody } from '@ingka/table';

import '@ingka/text/dist/style.css';
import '@ingka/button/dist/style.css';
import '@ingka/modal/dist/style.css';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/focus/dist/style.css';
import '@ingka/table/dist/style.css';
import { entityRouteRef } from '@backstage/plugin-catalog-react';
import { useRouteRef } from '@backstage/core-plugin-api';
import { Link } from '@backstage/core-components';

const useStyles = makeStyles(theme => ({
  modalButton: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    background: 'transparent',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
  },
  customPadding: {
    padding: '12px !important',
  },
}));

interface GroupsComponents {
  groupName: string;
  components: CompoundEntityRef[];
}

export const Mod = ({
  groupComponents,
  kind,
  doc,
}: {
  groupComponents: GroupsComponents[];
  kind: string;
  doc?: Boolean;
}) => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = React.useState(false);
  const entityUrl = useRouteRef(entityRouteRef);

  const handleButtonClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <button onClick={handleButtonClick} className={classes.modalButton}>
        View More
        <SSRIcon paths={arrowRight} />
      </button>
      <Modal visible={modalOpen} handleCloseBtn={handleCloseModal}>
        <Sheets
          alignment="right"
          preserveAlignment={false}
          size="medium"
          header={
            <ModalHeader
              titleId="Components"
              title="Your Teams Ownership"
              floating={false}
            />
          }
          footer={
            <ModalFooter>
              <></>
            </ModalFooter>
          }
        >
          <ModalBody>
            {groupComponents.map((item, index) => (
              <div key={index}>
                <Text tagName="h5" bodySize="m">
                  {item.groupName} owns {item.components.length} {kind}
                </Text>
                <Table fullWidth inset>
                  <TableBody striped>
                    {item.components.map(comp => (
                      <tr>
                        <td className={classes.customPadding}>
                          <Link
                            underline="always"
                            to={
                              doc ? `${entityUrl(comp)}/docs` : entityUrl(comp)
                            }
                          >
                            {comp.name}
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </TableBody>
                </Table>
              </div>
            ))}
          </ModalBody>
        </Sheets>
      </Modal>
    </>
  );
};
