import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import {
  catalogApiRef,
  getEntityRelations,
} from '@backstage/plugin-catalog-react';
import {
  CompoundEntityRef,
  Entity,
  GroupEntity,
  RELATION_OWNER_OF,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { Link } from '@backstage/core-components';
import { githubAuthApiRef, useApi } from '@backstage/core-plugin-api';
import PRCard from './PRCard';
import { RepoDetails, PullRequest, fetchPullRequests } from './GithubFetch';

import Text from '@ingka/text';
import Loading, { LoadingBall } from '@ingka/loading';

import '@ingka/text/dist/style.css';
import '@ingka/hyperlink/dist/style.css';
import '@ingka/loading/dist/style.css';

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: '3px',
    border: '0.5px solid #E8E8E8',
    padding: theme.spacing(1.5, 3),
    paddingBottom: theme.spacing(3),
    background:
      'linear-gradient(90deg, rgba(235,246,255,1) 0%, rgba(255,255,255,1) 100%)',
    height: '300px',
  },
  mSmall: {
    margin: theme.spacing(1, 0),
  },
}));

interface GroupEntitiesProps {
  groupEntities: GroupEntity[];
}

const getRepoDetails = (url: string | undefined): RepoDetails => {
  if (!url) {
    throw new Error('GitHub repository URL not found in entity annotations.');
  }

  const urlMatch = url.match(/https:\/\/github\.com\/([^\/]+)\/([^\/]+)/);
  if (!urlMatch) {
    throw new Error('Invalid GitHub repository URL format.');
  }

  return { owner: urlMatch[1], repo: urlMatch[2] };
};

export const PRWidget: React.FC<GroupEntitiesProps> = ({ groupEntities }) => {
  const classes = useStyles();
  const catalogApi = useApi(catalogApiRef);
  const githubAuth = useApi(githubAuthApiRef);

  const [loading, setLoading] = React.useState(true);
  const [componentArray, setComponentArray] = React.useState<Entity[]>([]);
  const [repos, setRepos] = React.useState<RepoDetails[]>([]);
  const [pullRequests, setPullRequests] = React.useState<PullRequest[]>([]);
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    const fetchComponents = async () => {
      if (groupEntities.length === 0) {
        setLoading(false);
        return;
      }

      try {
        const compArray: string[] = [];

        for (const item of groupEntities) {
          const entityRefs: CompoundEntityRef[] = getEntityRelations(
            item,
            RELATION_OWNER_OF,
            { kind: 'Component' },
          );
          const stringifiedEntityRefs = entityRefs.map(entityRef =>
            stringifyEntityRef(entityRef),
          );
          compArray.push(...stringifiedEntityRefs);
        }

        const completeEntities = await catalogApi.getEntitiesByRefs({
          entityRefs: compArray,
        });
        const filteredEntities = completeEntities.items.filter(
          (item): item is Entity => item !== undefined,
        );

        const repoArr = filteredEntities.map(entity =>
          getRepoDetails(
            entity.metadata.annotations?.['backstage.io/managed-by-location'],
          ),
        );

        setRepos(repoArr);
        setComponentArray(filteredEntities);
      } catch (err) {
        setError('Failed to fetch components');
      } finally {
        setLoading(false);
      }
    };

    fetchComponents();
  }, [catalogApi, groupEntities]);

  React.useEffect(() => {
    if (repos.length === 0) return;

    const fetchData = async () => {
      setLoading(true);
      try {
        const token = await githubAuth.getAccessToken(['repo:status']);
        const prData = await fetchPullRequests(repos, token);
        setPullRequests(prData);
      } catch (err) {
        setError(`Failed to fetch pull requests: ${err}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [repos, githubAuth]);

  if (loading) {
    return (
      <div className={classes.card}>
        <Text tagName="h1" bodySize="l" className={classes.mSmall}>
          GitHub PRs for Review ({pullRequests.length})
        </Text>
        <Text tagName="p" bodySize="s" className={classes.mSmall}>
          Here you can find open PRs of your team Repos
        </Text>
        <Loading text="Loading Pull Requests">
          <LoadingBall />
        </Loading>
      </div>
    );
  }

  if (error) {
    return (
      <div className={classes.card}>
        <Text tagName="h1" bodySize="l" className={classes.mSmall}>
          GitHub PRs for Review
        </Text>
        <Text tagName="p" bodySize="s" className={classes.mSmall}>
          Here you can find open PRs of your team Repos
        </Text>
        <Text tagName="p" bodySize="s" className={classes.mSmall}>
          {error}
        </Text>
      </div>
    );
  }

  if (componentArray.length === 0) {
    return (
      <div className={classes.card}>
        <Text tagName="h1" bodySize="l" className={classes.mSmall}>
          GitHub PRs for Review
        </Text>
        <Text tagName="p" bodySize="s" className={classes.mSmall}>
          Here you can find open PRs of your team Repos
        </Text>
        <br />
        <Text tagName="h4" bodySize="m" className={classes.mSmall}>
          No Components found!
        </Text>
        <Text tagName="p" bodySize="s" className={classes.mSmall}>
          You can register your components{' '}
          <Link to="/catalog-import">here.</Link>
        </Text>
      </div>
    );
  }

  if (pullRequests.length === 0) {
    return (
      <div className={classes.card}>
        <Text tagName="h1" bodySize="l" className={classes.mSmall}>
          GitHub PRs for Review
        </Text>
        <Text tagName="p" bodySize="s" className={classes.mSmall}>
          Here you can find open PRs of your team Repos
        </Text>
        <Text tagName="p" bodySize="s" className={classes.mSmall}>
          No open PRs found
        </Text>
      </div>
    );
  }

  return (
    <div className={classes.card}>
      <Text tagName="h1" bodySize="l" className={classes.mSmall}>
        GitHub PRs for Review ({pullRequests.length})
      </Text>
      <Text tagName="p" bodySize="s" className={classes.mSmall}>
        Here you can find open PRs of your team Repos
      </Text>
      <PRCard pullRequests={pullRequests} />
    </div>
  );
};
