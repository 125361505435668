import {
  Entity,
  EntityRelation,
  parseEntityRef,
} from '@backstage/catalog-model';
import { ColumnFilterOption } from './ColumnFilter';
import { JsonObject } from '@backstage/types';

export const getApiLink = (entity: {
  apiVersion?: string;
  kind: string;
  metadata: JsonObject | undefined;
  spec?: JsonObject | undefined;
  relations?: EntityRelation[] | undefined;
}) => {
  let kind;
  let namespace;
  let name;

  if ('metadata' in entity) {
    kind = entity.kind;
    namespace = entity?.metadata?.namespace;
    name = entity?.metadata?.name;
  }
  kind = kind?.toLocaleLowerCase('en-US');
  namespace =
    typeof namespace === 'string'
      ? namespace.toLocaleLowerCase('en-US')
      : namespace;
  const link = `catalog/${namespace}/${kind}/${name}`;
  return { link };
};

export function parseOwner(owner: string) {
  return parseOwnerEntity('owner', owner);
}

function parseOwnerEntity(entityType: string, entityName: string) {
  try {
    const entity = parseEntityRef(entityName ?? 'not-available', {
      defaultKind: entityType,
      defaultNamespace: 'default',
    });
    let name: string = entity?.name.toLocaleLowerCase('en-US');
    if (name === 'na' || name.trim().length === 0 || !name) {
      name = 'not-available';
    }
    return name;
  } catch (err) {
    throw err;
  }
}

export function getColumnFilterOption(data: Entity[]): ColumnFilterOption {
  const options: ColumnFilterOption = {
    environment: ['prod', 'ppe', 'qa', 'dev'],
    architectureArea: [
      ...new Set(
        data
          .map(item => item.metadata.labels?.architectureArea?.trim())
          .filter(item => item)
          .sort(),
      ),
    ],
  };

  return options;
}

export function filterDataByProps(
  data: Entity[] | undefined,
  selectedEnvironment: string[],
  architectureArea: string[],
) {
  let result = data;
  if (data && data.length > 0) {
    result = data.filter(item => {
      let isEnv = true;
      let isArchitectureArea = true;
      if (selectedEnvironment.length > 0) {
        isEnv = selectedEnvironment.some(
          (x: string) =>
            typeof item?.spec?.environment === 'string' &&
            x
              .toLocaleLowerCase()
              .includes(
                item?.spec?.environment
                  ?.toLocaleLowerCase()
                  .replace(/\s{2,}/g, ' '),
              ),
        );
      }
      if (architectureArea.length > 0) {
        isArchitectureArea = architectureArea.some(
          (x: string) =>
            typeof item?.metadata?.labels?.architectureArea === 'string' &&
            x
              .toLocaleLowerCase()
              .includes(
                item?.metadata?.labels?.architectureArea?.toLocaleLowerCase(),
              ),
        );
      }
      return isEnv && isArchitectureArea;
    });
  }

  return result;
}
