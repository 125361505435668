import { TextField } from '@material-ui/core';
import * as React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Autocomplete } from '@material-ui/lab';
import { TCONFData, useConfArchArea } from '../../hooks/useApiHooks';
import { useEffect, useState } from 'react';

interface ArchitectureAreaFilterProps {
  setArchitectureArea: React.Dispatch<string[] | undefined>;
}

export const ArchitectureAreaFilter = (props: ArchitectureAreaFilterProps) => {
  // The securityTiers key is recognized due to the CustomFilter generic
  const architectureArea: TCONFData = useConfArchArea();
  const [selectedArchitectureArea, setSelectedArchitectureArea] = useState<
    string[] | undefined
  >();
  const { setArchitectureArea } = props;
  const onSelectArchitectureAreaFilter = () => {
    if (selectedArchitectureArea && selectedArchitectureArea.length > 0) {
      setArchitectureArea(selectedArchitectureArea);
    } else {
      const architectureAreaArray = architectureArea.architectureArea || [];
      setArchitectureArea(architectureAreaArray);
    }
  };

  useEffect(() => {
    onSelectArchitectureAreaFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArchitectureArea]);

  return (
    <Autocomplete
      multiple
      options={architectureArea.architectureArea || ['Architecture area']}
      onChange={(_: React.ChangeEvent<{}>, newInputValue) => {
        setSelectedArchitectureArea(newInputValue);
      }}
      size="small"
      popupIcon={<ExpandMoreIcon data-testid="tag-picker-expand" />}
      renderInput={params => (
        <TextField {...params} label="Architecture area" variant="outlined" />
      )}
    />
  );
};
