import {
  Backdrop,
  Box,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Modal,
  Paper,
  Theme,
} from '@material-ui/core';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { getApiLink } from '../utils';
import Hyperlink from '@ingka/hyperlink';
import '@ingka/hyperlink/dist/style.css';
import { Pagination } from '@material-ui/lab';
import usePagination from './Pagination';
import ClearButton from '@material-ui/icons/Clear';
import { Entity } from '@backstage/catalog-model';
import Button from '@ingka/button';
import '@ingka/button/dist/style.css';

interface SearchProps {
  data: Entity[];
}
const useStyles = makeStyles<Theme>(theme => ({
  modal: {
    display: 'flex',
    width: '100%',
    height: '100%',
    marginBottom: theme.spacing(1),
  },
  searchButton: {
    fontFamily: 'Noto IKEA',
  },
  boxContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '60em',
    width: '60%',
    height: '80%',
    borderRadius: '10px',
  },
  paperContainer: {
    borderRadius: 30,
    display: 'flex',
    height: '2.4em',
  },
  contentContainer: {
    overflow: 'auto',
    height: '80%',
  },
  flexContainer: {
    flexWrap: 'wrap',
  },
  itemText: {
    width: '100%',
    wordBreak: 'break-all',
    marginBottom: '1rem',
  },
}));

export const Search = ({ data }: SearchProps) => {
  const classes = useStyles();
  const PER_PAGE = 20;

  const [searchTerm, setSearchTerm] = useState('');
  const [apis, setApis] = useState<Entity[]>([]);
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = useState(1);

  const count = Math.ceil(apis.length / PER_PAGE);
  const _DATA = usePagination(apis, PER_PAGE);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangePagination = (
    _e: ChangeEvent<unknown>,
    p: React.SetStateAction<number>,
  ) => {
    setPage(p);
    _DATA.jump(p);
  };

  const handleResultClick = () => {
    handleClose();
  };

  const handleClear = useCallback(() => {
    setSearchTerm('');
    setApis(data);
  }, [data]);

  const handleChangeTerm = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(e.target.value);
    },
    [setSearchTerm],
  );

  const filterData = useCallback(() => {
    const result = data?.filter((item: Entity) => {
      return (
        item.metadata?.name
          ?.toLocaleLowerCase()
          .replace(/\s{2,}/g, ' ')
          .includes(
            searchTerm
              .toLocaleLowerCase()
              .replace(/\s{2,}/g, ' ')
              .trim(),
          ) ||
        item.metadata?.title
          ?.toLocaleLowerCase()
          .replace(/\s{2,}/g, ' ')
          .includes(
            searchTerm
              .toLocaleLowerCase()
              .replace(/\s{2,}/g, ' ')
              .trim(),
          )
      );
    });
    return result;
  }, [data, searchTerm]);

  useEffect(() => {
    setApis(filterData());
  }, [filterData, searchTerm]);

  useEffect(() => {
    setApis(data);
  }, [data]);

  const startAdornment = (
    <InputAdornment position="start">
      <IconButton aria-label="Query" disabled>
        <SearchIcon />
      </IconButton>
    </InputAdornment>
  );

  const endAdornment = (
    <InputAdornment position="end">
      <IconButton aria-label="Clear" onClick={handleClear}>
        <ClearButton />
      </IconButton>
    </InputAdornment>
  );

  return (
    <>
      <Button
        className={`${classes.searchButton} ${'btn btn--primary'}`}
        onClick={handleOpen}
      >
        Search APIS
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        className={classes.modal}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            className={classes.boxContainer}
            bgcolor="background.paper"
            boxShadow="3"
            p="4"
          >
            <DialogTitle>
              <Paper className={classes.paperContainer}>
                <InputBase
                  value={searchTerm}
                  placeholder="Search In API Catalog"
                  startAdornment={startAdornment}
                  endAdornment={endAdornment}
                  inputProps={{ 'aria-label': 'Search' }}
                  fullWidth
                  onChange={handleChangeTerm}
                />
              </Paper>
            </DialogTitle>
            <DialogContent className={classes.contentContainer}>
              <Grid container direction="column">
                <Grid item xs>
                  <List>
                    {_DATA.currentData().map((entity: Entity) => {
                      const { link } = getApiLink(entity);
                      return (
                        <>
                          <ListItem alignItems="flex-start">
                            <div className={classes.flexContainer}>
                              <Hyperlink
                                url={`/${link}`}
                                onClick={handleResultClick}
                              >
                                <ListItemText
                                  className={classes.itemText}
                                  primaryTypographyProps={{ variant: 'h6' }}
                                  primary={entity.metadata.title}
                                  secondary={entity.metadata.description}
                                />
                              </Hyperlink>
                              <Box>
                                {entity?.spec?.type && (
                                  <Chip
                                    label={`Type: ${entity.spec.type}`}
                                    size="small"
                                  />
                                )}
                                {entity?.spec?.lifecycle &&
                                  entity.spec.type !== 'openapi' && (
                                    <Chip
                                      label={`Lifecycle: ${entity.spec.lifecycle}`}
                                      size="small"
                                    />
                                  )}
                              </Box>
                            </div>
                          </ListItem>
                          <Divider component="li" />
                        </>
                      );
                    })}
                  </List>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid container direction="row">
                <Grid item xs={12}>
                  <Pagination
                    count={count}
                    page={page}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleChangePagination}
                  />
                </Grid>
              </Grid>
            </DialogActions>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
