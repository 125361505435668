export const columns = [
  {
    title: 'Name',
    width: '17%',
  },
  {
    title: 'Version',
    width: '15%',
  },
  {
    title: 'Environment',
    width: '15%',
  },
  {
    title: 'Type',
    width: '15%',
  },
  {
    title: 'Lifecycle',
    width: '15%',
  },
  {
    title: 'Architecture area',
    width: '15%',
  },
  {
    title: 'Rating',
    width: '15%',
  },
];
