import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { getEntityRelations } from '@backstage/plugin-catalog-react';
import {
  CompoundEntityRef,
  GroupEntity,
  RELATION_OWNER_OF,
} from '@backstage/catalog-model';

import { Mod } from './Modal';
import Text from '@ingka/text';
import { Link } from '@backstage/core-components';

import '@ingka/text/dist/style.css';
import '@ingka/hyperlink/dist/style.css';

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: 'black',
    padding: theme.spacing(2.5),
    height: '180px',
    '& h1': {
      color: 'white',
    },
    '& p': {
      color: '#c4c4c4',
    },
  },
  mSmall: {
    margin: theme.spacing(1, 0),
  },
}));

interface GroupsComponents {
  groupName: string;
  components: CompoundEntityRef[];
}

interface OwnershipCardProps {
  groupEntities: GroupEntity[];
  kind: string;
  desc: string;
}

export const OwnerShipCard: React.FC<OwnershipCardProps> = ({
  groupEntities,
  kind,
  desc,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [componentCount, setComponentCount] = React.useState<number>(0);
  const [groupComponents, setGroupComponents] = React.useState<
    GroupsComponents[]
  >([]);

  React.useEffect(() => {
    const fetchComponents = async () => {
      if (groupEntities.length === 0) {
        setLoading(false);
        return;
      }

      const groupArray: GroupsComponents[] = [];
      for (const item of groupEntities) {
        const entityRefs: CompoundEntityRef[] = getEntityRelations(
          item,
          RELATION_OWNER_OF,
          { kind: kind },
        );
        groupArray.push({
          groupName: item.metadata.name,
          components: entityRefs,
        });
      }

      setGroupComponents(groupArray);
      setComponentCount(
        groupArray.reduce((sum, group) => sum + group.components.length, 0),
      );
      setLoading(false);
    };

    fetchComponents();
  }, [groupEntities, kind]);

  if (loading) {
    return (
      <>
        <div className={classes.card}>
          <Text tagName="h1" bodySize="l" className={classes.mSmall}>
            Loading
          </Text>
        </div>
      </>
    );
  }

  if (componentCount === 0) {
    return (
      <div className={classes.card}>
        <Text tagName="h1" bodySize="l" className={classes.mSmall}>
          No {kind}
        </Text>
        <Text tagName="p" bodySize="s" className={classes.mSmall}>
          Your teams have no {kind} you can register {kind} from{' '}
          <Link color="inherit" underline="always" to="/create">
            here
          </Link>
        </Text>
      </div>
    );
  }

  return (
    <div className={classes.card}>
      <Text tagName="h1" bodySize="l" className={classes.mSmall}>
        {componentCount} {kind}
      </Text>
      <Text tagName="p" bodySize="s" className={classes.mSmall}>
        {desc}
      </Text>
      <br />
      <hr />
      <Mod groupComponents={groupComponents} kind={kind} />
    </div>
  );
};
