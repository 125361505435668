export function getArchitecureAreas() {
  return {
    architectureAreas: [
      'MEDIA',
      'RPCM',
      'MKTG',
      'ISPC',
      'PROC',
      'SnOP',
      'NDO',
      'DSP',
      'QDM',
      'ORD',
      'TMS',
      'CUST',
      'WHM',
      'SCL',
      'SCT',
      'PDE',
      'RPM',
      'PACM',
      'FIN',
      'TRM',
      'LEARN',
      'HRCM',
      'EDnA',
      'ADM',
      'DDM',
      'ITSMO',
      'EXP',
      'ICON',
      'FRM',
      'TO_BE_DEFINED',
    ],
  };
}
