import { createPermission } from '@backstage/plugin-permission-common';

export const awscoreAccessPermissionRole =
  'group:default/external.csm.integration';

/**
 * Permission definition.
 *
 * @public
 */
export const awscoreAccessPermission = createPermission({
  name: 'awscore.access',
  attributes: { action: 'read' },
});

/**
 * List of all awscore permissions.
 *
 * @public
 */
export const awscorePermissions = [awscoreAccessPermission];
