import * as React from 'react';
import Modal, { ModalHeader, ModalFooter, Prompt } from '@ingka/modal';
import Text from '@ingka/text';
import SSRIcon from '@ingka/ssr-icon';
import informativeIcon from '@ingka/ssr-icon/paths/information-circle';
import List from '@ingka/list';

import '@ingka/list/dist/style.css';
import '@ingka/text/dist/style.css';
import '@ingka/button/dist/style.css';
import '@ingka/modal/dist/style.css';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/focus/dist/style.css';

export const SplunkDocsModal = () => {
  const [modalOpen, setModalOpen] = React.useState(false);

  const handleButtonClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <SSRIcon
        data-testid="ssr-icon"
        paths={informativeIcon}
        onClick={handleButtonClick}
      />
      <Modal
        data-testid="modal"
        visible={modalOpen}
        handleCloseBtn={handleCloseModal}
      >
        <Prompt
          titleId="SplunkDocsModal"
          title="Setup Splunk Metrics"
          header={<ModalHeader ariaCloseTxt="Close prompt" />}
          footer={
            <ModalFooter>
              <></>
            </ModalFooter>
          }
        >
          <>
            <Text tagName="p" bodySize="s">
              Splunk Observability provides insight data(Js Errors, Netwrok
              requests, Backend Latency) for you application. To get started,
              you need to
              <br />
              <br />
              <List ordered variation="medium">
                <li>
                  Create a RUM application in interikea.signalfx.com(Splunk
                  observability) and setup application to send metrics to RUM.
                  Insights is only available for RUM applications with prod
                  environment.
                </li>
                <li>
                  Register the component in Samspela and add the annotation
                  'backstage.io/splunk-app: 'Your-RUM-application-name'' in
                  catalog-info.yaml.
                </li>
              </List>
            </Text>
          </>
        </Prompt>
      </Modal>
    </>
  );
};
