import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import '../Common/style.css';
import Text from '@ingka/text';
import Hyperlink from '@ingka/hyperlink';
import '@ingka/hyperlink/dist/style.css';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ApiEntity } from '@backstage/catalog-model';
import { useEntity } from '@backstage/plugin-catalog-react';
import { useFrontendConfig } from '@internal/common-frontend';

export const OnboardingAssistancePage = () => {
  const config = useFrontendConfig();
  const swaggerUrl = config.getString('apim.swaggerDocUrl');
  const apiCatalogUrl = config.getString('apim.apiCatalogUrl');
  const archAreaUrl = config.getString('apim.archAreaUrl');
  const appRegUrl = config.getString('apim.appRegUrl');
  const channelUrl = config.getString('apim.channelUrl');
  const oAuthUrl = config.getString('apim.oauthUrl');
  const endpointUrl = config.getString('apim.endpointUrl');
  const githubUrl = config.getString('apim.githubUrl');
  const { entity } = useEntity<ApiEntity>();
  const onboardingUrl = entity?.metadata.annotations?.onboardingUrl;
  const apiName = entity?.metadata.title;
  const Spacing = () => <span className="spacing" />;

  const OnboardingInstructionsText = () => {
    return (
      <Grid item xs={12}>
        <Text headingSize="m" tagName="h1">
          How to Onboard
        </Text>

        <Text bodySize="l">
          To initiate API onboarding, refer to the instructions provided below.
          Teams may offer documentation links or communication channels for
          assistance. If no instructions are available, check the Swagger UI
          documentation's contact section.
        </Text>
      </Grid>
    );
  };

  const GeneralInstructionsText = () => {
    return (
      <Grid item xs={12}>
        <Text headingSize="m" tagName="h1">
          No Instructions available
        </Text>
        <Text bodySize="l">
          Since no instructions are available, check the Swagger UI
          documentation's contact section. If still unclear, reach out to
          <Hyperlink url={channelUrl} style={{ color: 'blue' }} target="_blank">
            <Spacing />
            (APIM Team)
          </Hyperlink>{' '}
          for guidance on connecting with the respective providers.
        </Text>
      </Grid>
    );
  };

  if (apiName?.includes('catalog')) {
    return (
      <Grid item xs={12}>
        <Accordion defaultExpanded style={{ width: '100%' }}>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            expandIcon={<ExpandMoreIcon />}
          >
            <Text headingSize="m" tagName="h1">
              Catalog API onboarding procedures - Prod
            </Text>
          </AccordionSummary>
          <AccordionDetails>
            <Text bodySize="l">
              <b>Pre requisites</b>
              <ul>
                <li>
                  Onboarding meeting will be arranged by APIM team where we
                  briefly explain the procedures.
                </li>
                <li>
                  please create an
                  <Spacing />
                  <Hyperlink
                    url={appRegUrl}
                    style={{ color: 'blue' }}
                    target="_blank"
                  >
                    application
                  </Hyperlink>
                  <Spacing />
                  within the Microsoft Azure production tenant to enable JWT
                  authentication with our API.
                </li>
                <li>
                  Kindly provide us with the client ID of your application to
                  facilitate our API subscription.
                </li>
              </ul>
              <b>Publish</b>

              <ul>
                <li>
                  Once you have completed the pre-requisites, you are ready to
                  publish your API to the API Catalog.
                </li>
                <li>
                  Utilize the following cURL request to generate Azure JWT token
                  for authentication:
                  <pre>
                    <code>
                      {`curl --location ${oAuthUrl} \\
--header 'Content-Type: application/x-www-form-urlencoded' \\
--data-urlencode 'client_id={{CLIENT_ID}}' \\
--data-urlencode 'client_secret={{CLIENT_SECRET}}' \\
--data-urlencode 'grant_type=client_credentials' \\
--data-urlencode 'scope={{CLIENT_SCOPE}}/.default'`}
                    </code>
                  </pre>
                </li>
                <li>
                  Utilize the following cURL request to submit your API to the
                  API catalog, providing the JWT Token generated from previous
                  step and required data in payload:
                  <pre>
                    <code>
                      {`curl --location ${endpointUrl} \\
--header 'Content-Type: application/json' \\
--header 'Authorization: {{JWT_TOKEN}}' \\
--data '{
  "apiType": "rest",
  "architectureArea": "{{ARCH_AREA}}",
  "catalogType": "business",
  "env": "{{ENV}",
  "githubRawUrl": ${githubUrl},
  "isPrivate": false,
  "proxyServiceName": "optional",
  "onboardingUrl": "optional",
  "serverlUrls": ["optional"],
  "owner": "optional"
}'`}
                    </code>
                  </pre>
                </li>
                <li>
                  The scope of application should be extended with '.default,'
                  for example: 'api://2b6f6f92-87ec-4bcc-848e-182321343.default
                </li>
                <li>
                  To discover your architectureArea, refer to the documentation
                  link -<Spacing />
                  <Hyperlink
                    url={archAreaUrl}
                    style={{ color: 'blue' }}
                    target="_blank"
                  >
                    Architecture areas
                  </Hyperlink>
                </li>
                <li>
                  To discover documentation, refer to the swagger documentation
                  link -<Spacing />
                  <Hyperlink
                    url={swaggerUrl}
                    style={{ color: 'blue' }}
                    target="_blank"
                  >
                    Swagger documentation link here.
                  </Hyperlink>
                </li>
                <li>
                  If you require a Postman collection, please feel free to
                  request it, and we will promptly share the collection with
                  you. We will also assess whether an additional license is
                  required for this.
                </li>
                <li>
                  After successfully publishing the APIs, you can explore your
                  APIs in the
                  <Spacing />
                  <Hyperlink
                    url={apiCatalogUrl}
                    style={{ color: 'blue' }}
                    target="_blank"
                  >
                    API catalog
                  </Hyperlink>
                  .
                </li>
              </ul>
              <b>Support</b>
              <ul>
                <li>
                  All required access can be obtained by reaching out through
                  the onboarding meeting invitation chat.
                </li>
              </ul>
            </Text>
          </AccordionDetails>
        </Accordion>
      </Grid>
    );
  } else if (onboardingUrl?.includes('atlassian.net')) {
    return (
      <Grid item xs={12}>
        <OnboardingInstructionsText />
        <Text headingSize="m" tagName="h1">
          Confluence documentation
        </Text>
        <Text bodySize="l">
          The documentation detailing how to consume the API can be found
          <Spacing />
          <Hyperlink
            url={onboardingUrl}
            target="_blank"
            style={{ color: 'blue' }}
          >
            here
          </Hyperlink>
        </Text>
      </Grid>
    );
  } else if (onboardingUrl?.includes('teams')) {
    return (
      <Grid item xs={12}>
        <OnboardingInstructionsText />
        <Text headingSize="m" tagName="h1">
          Communication channel
        </Text>
        <Text bodySize="l">
          The Provider team can be reached through the <Spacing />
          <Hyperlink
            url={onboardingUrl}
            target="_blank"
            style={{ color: 'blue' }}
          >
            Teams channel
          </Hyperlink>
        </Text>
      </Grid>
    );
  }
  return (
    <Grid item xs={12}>
      <GeneralInstructionsText />
    </Grid>
  );
};
