import { Content, ContentHeader } from '@backstage/core-components';
import React, { useEffect, useState } from 'react';
import { ArchitectureAreaFilter, Search } from '.';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import { columns as webApiColumns } from '../apim';
import {
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Tab,
} from '@material-ui/core';
import { ApiTable } from './ApiTable';
import { getColumnFilterOption, filterDataByProps } from './utils';
import { ColumnFilter, ColumnFilterOption } from './ColumnFilter';
import { Entity } from '@backstage/catalog-model';

const useStyles = makeStyles(theme =>
  createStyles({
    tabMargin: {
      paddingLeft: '0',
      paddingRight: '0',
    },
    filterMargin: {
      marginTop: '10px',
      marginBottom: '10px',
    },
    content: {
      paddingBottom: theme.spacing(0),
      marginBottom: '0',
      height: '88vh',
    },
  }),
);

export interface IApiEntity {
  apiVersion: string;
  kind: string;
  metadata: {
    title: string;
    name: string;
    description: string;
    namespace: string;
    labels: {
      version: string;
      modifiedDate: string;
      type: string;
      architectureArea: string;
    };
  };
  spec: {
    type: string;
    lifecycle: string;
    environment?: string;
    owner: string;
    definition: string;
  };
}

export const ApiCatalog = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [tab, setTab] = useState(queryParams.get('apis') || 'openapi');
  const catalogApi = useApi(catalogApiRef);
  const [page, setPage] = React.useState(0);
  const [apiData, setApiData] = useState<Entity[]>();
  const [apiMasterData, setApiMasterData] = useState<Entity[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedEnvironment, setSelectedEnvironment] = useState<string[]>([]);
  const [architectureArea, setArchitectureArea] = useState<string[]>();
  const [apiColumnFilterOption, setApiColumnFilterOption] =
    useState<ColumnFilterOption>();

  const classes = useStyles();
  async function populateAPICatalogData(_spec: string) {
    try {
      setLoading(true);
      const { items } = await catalogApi.getEntities({
        fields: [],
        filter: {
          kind: ['api'],
          'spec.type': _spec,
        },
      });

      items.sort((api1, api2) => {
        const title1 = api1.metadata?.title?.toLocaleLowerCase();
        const title2 = api2.metadata?.title?.toLocaleLowerCase();
        if (!title1 && title2) {
          return 1;
        }
        if (!title2 && title1) {
          return -1;
        }
        if (!title2 || !title1) {
          return 0;
        }
        if (title1 < title2) {
          return -1;
        }
        if (title1 > title2) {
          return 1;
        }
        return 0;
      });

      if (_spec === 'openapi') {
        setApiData(items);
        setApiMasterData(items);
        setLoading(false);
        setApiColumnFilterOption(getColumnFilterOption(items));
      } else {
        setLoading(false);
      }
    } catch (error) {
      throw error;
    }
  }

  useEffect(() => {
    let filteredData;
    if (tab === 'openapi') {
      setApiData(apiMasterData);
      filteredData = filterDataByProps(
        apiMasterData,
        selectedEnvironment || [],
        architectureArea || [],
      );
      setApiData(filteredData);
    }
    setPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEnvironment, architectureArea, apiMasterData]);

  const handleTabChange = (
    _event: React.ChangeEvent<{}>,
    newValue: string,
  ): void => {
    setTab(newValue);
  };

  useEffect(() => {
    if (tab === 'openapi') {
      setSelectedEnvironment(['prod']);
    } else {
      setSelectedEnvironment([]);
    }
  }, [tab]);

  useEffect(() => {
    populateAPICatalogData('openapi');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Content className={classes.content}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <ArchitectureAreaFilter setArchitectureArea={setArchitectureArea} />
        </Grid>
        <Grid item xs={8}>
          <ContentHeader title="">
            <Search
              data={[...(apiMasterData || [])].sort((a, b) =>
                (a?.metadata?.title || '').localeCompare(
                  b?.metadata?.title || '',
                ),
              )}
            />
          </ContentHeader>
        </Grid>
      </Grid>
      <TabContext value={tab}>
        <TabList
          onChange={handleTabChange}
          TabIndicatorProps={{ style: { background: '#0058A3' } }}
        >
          <Tab label="Web API" value="openapi" />
        </TabList>
        <Divider />
        <TabPanel value="openapi" className={classes.tabMargin}>
          <ColumnFilter
            tab={tab}
            columnFilterOption={apiColumnFilterOption}
            setSelectedEnvironment={setSelectedEnvironment}
          />
          <br />
          <ApiTable
            columns={webApiColumns}
            apis={apiData}
            page={page}
            setPage={setPage}
            loading={loading}
          />
        </TabPanel>
      </TabContext>
    </Content>
  );
};
