import React, { PropsWithChildren } from 'react';
import { Navigate, Route } from 'react-router';
import { apiDocsPlugin } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';
import {
  AlertDisplay,
  AutoLogout,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { maintainersAccessPermission } from '@internal/plugin-maintainers-common';
import { overrideLightTheme } from './theme';
import { ApiCatalogPage, ApimPage } from 'backstage-plugin-apim';
import { microsoftAuthApiRef } from '@backstage/core-plugin-api';
import { UnifiedThemeProvider } from '@backstage/theme';
import { GetAccessPage } from '@internal/plugin-get-access';
import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';
import { OwnerFieldExtension } from './components/scaffolderFieldExtensions/InterIKEABriljeraOwner';
import { setTokenCookie } from './cookieAuth';
import type { IdentityApi } from '@backstage/core-plugin-api';
import {
  discoveryApiRef,
  useApi,
  useAnalytics,
} from '@backstage/core-plugin-api';
import { CatalogOwnerFieldExtension } from './components/scaffolderFieldExtensions/SoftwareCatalogOwner';
import { BriljeraErrorPage } from './components/errorPage';
import { MaintainersPage } from '@internal/plugin-maintainers';
import { AwscorePage } from '@internal/plugin-awscore';
import { awscoreAccessPermission } from '@internal/plugin-awscore-common';
import { CustomizedCatalogPage } from './components/catalog/CustomizedCatalogPage';
import { CustomizedTechDocsIndexPage } from './components/techdocs/CustomizedTechDocsIndexPage';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { HomePage } from './components/home/HomePage';
import { ServiceRegistrationGuide } from './components/catalog/ServiceRegistrationGuide';
import { TechdocsStyling } from './components/techdocs/TechdocsStyle';
import NiceModal from '@ebay/nice-modal-react';
import { LoadFrontendConfig } from '@internal/common-frontend';

const NotFoundErrorPage = () => (
  <BriljeraErrorPage status="404" statusMessage="PAGE NOT FOUND" />
);

const app = createApp({
  apis,
  components: {
    NotFoundErrorPage,
    ...(process.env.NODE_ENV === 'development'
      ? undefined
      : {
          SignInPage: props => {
            const discoveryApi = useApi(discoveryApiRef);
            const analyticsTracker = useAnalytics();
            return (
              <SignInPage
                {...props}
                provider={{
                  id: 'microsoft-auth-provider',
                  title: 'Microsoft Azure',
                  message: 'Sign in using Microsoft Azure SSO',
                  apiRef: microsoftAuthApiRef,
                }}
                onSignInSuccess={async (identityApi: IdentityApi) => {
                  setTokenCookie(
                    await discoveryApi.getBaseUrl('cookie'),
                    identityApi,
                    analyticsTracker,
                  );
                  props.onSignInSuccess(identityApi);
                }}
              />
            );
          },
        }),
  },
  themes: [
    {
      id: 'ikea-light',
      title: 'IKEA Theme',
      variant: 'light',
      Provider: ({ children }: PropsWithChildren<{}>) => (
        <UnifiedThemeProvider theme={overrideLightTheme} children={children} />
      ),
    },
  ],
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const AppProvider = app.getProvider();
const AppRouter = app.getRouter();

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="dashboard" />} />
    <Route path="/catalog" element={<CatalogIndexPage />}>
      <CustomizedCatalogPage />
    </Route>
    <Route path="/dashboard" element={<HomePage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />}>
      <CustomizedTechDocsIndexPage />
    </Route>
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <TechdocsStyling />
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route
      path="/create"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <ScaffolderPage />
        </RequirePermission>
      }
    >
      <ScaffolderFieldExtensions>
        <OwnerFieldExtension />
        <CatalogOwnerFieldExtension />
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/api-docs" element={<ApiCatalogPage />} />
    <Route
      path="/tech-radar"
      element={
        <TechRadarPage width={1500} height={800} pageTitle="INTER IKEA RADAR" />
      }
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    >
      <ServiceRegistrationGuide />
    </Route>
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route
      path="/catalog-graph"
      element={<CatalogGraphPage showArrowHeads />}
    />
    <Route path="/apim" element={<ApimPage />} />
    <Route path="/samspela-info" element={<GetAccessPage />} />
    <Route
      path="/maintainers"
      element={
        <RequirePermission permission={maintainersAccessPermission}>
          <MaintainersPage />
        </RequirePermission>
      }
    />
    <Route
      path="/awscore"
      element={
        <RequirePermission permission={awscoreAccessPermission}>
          <AwscorePage />
        </RequirePermission>
      }
    />
  </FlatRoutes>
);

const App = () => (
  <AppProvider>
    <NiceModal.Provider>
      <LoadFrontendConfig />
      <AlertDisplay transientTimeoutMs={5000} />
      <OAuthRequestDialog />
      <AutoLogout
        idleTimeoutMinutes={60}
        promptBeforeIdleSeconds={30}
        useWorkerTimers={false}
      />
      <AppRouter>
        <Root>{routes}</Root>
      </AppRouter>
    </NiceModal.Provider>
  </AppProvider>
);

export default App;
