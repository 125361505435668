import React from 'react';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import {
  CatalogOwnerFieldOutputT,
  decodeToken,
  toCatalogOwnerFieldOutput,
} from '@briljera/common';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { useApi } from '@backstage/core-plugin-api';
import { microsoftAuthApiRef } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import { regEx } from '../Constants';
import * as jwt from 'jsonwebtoken';
import { LinearProgressIndicator } from '../../Common/LinearProgressIndicator';

export const CatalogOwnerField = (
  props: FieldExtensionComponentProps<string, {}>,
) => {
  const onChange = props.onChange;
  const { rawErrors, required, formData } = props.formContext;
  const microsoftAuth = useApi(microsoftAuthApiRef);
  const {
    value: deCodedToken,
    error,
    loading,
  } = useAsync(
    (): Promise<CatalogOwnerFieldOutputT> =>
      microsoftAuth
        .getIdToken()
        .then(token =>
          decodeToken(token).then(resp => (resp as jwt.JwtPayload)?.roles),
        ),
  );

  if (loading)
    return <LinearProgressIndicator title="Loading data, please wait ..." />;

  if (error) {
    return (
      <Typography variant="body1" color="primary">
        Data not present : {error?.message}
      </Typography>
    );
  }

  if (Array.isArray(deCodedToken)) {
    const roles: string[] = deCodedToken.filter(role => regEx.test(role));
    return (
      <FormControl
        margin="normal"
        required={required}
        error={
          rawErrors?.length !== undefined && rawErrors?.length > 0 && !formData
        }
      >
        <InputLabel htmlFor="CatalogOwnerFieldID">
          Catalog Component Owner
        </InputLabel>
        <Select
          id="CatalogOwnerFieldID"
          value={formData?.role || formData?.catalogOwnerObject}
          onChange={e => {
            const role = e.target?.value;
            onChange(
              toCatalogOwnerFieldOutput(roles, role),
              undefined,
              undefined,
            ); // Update the form data with the fixed value
          }}
        >
          {roles.map((item: string) => (
            <MenuItem value={item} key={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  if (required) {
    return (
      <Typography variant="body1" color="error">
        This field is required.
      </Typography>
    );
  }
  return null;
};

export const CatalogOwnerFieldLocal = (
  props: FieldExtensionComponentProps<string, {}>,
) => {
  const { rawErrors, required, formData } = props.formContext;
  const deCodedToken = ['dp.local-dev.team'];

  const onChange = props.onChange;

  if (Array.isArray(deCodedToken)) {
    const roles: string[] = deCodedToken.filter(role => regEx.test(role));

    return (
      <FormControl
        margin="normal"
        required={required}
        error={
          rawErrors?.length !== undefined && rawErrors?.length > 0 && !formData
        }
      >
        <InputLabel htmlFor="CatalogOwnerFieldID">
          Catalog Component Owner
        </InputLabel>
        <Select
          id="CatalogOwnerFieldID"
          value={formData?.role || formData?.catalogOwnerObject}
          onChange={e => {
            const role = e.target?.value as string;
            const selected = toCatalogOwnerFieldOutput(roles, role);
            onChange(selected); // Update the form data with the fixed value
          }}
        >
          {roles.map((item: string) => (
            <MenuItem value={item} key={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  if (required) {
    return (
      <Typography variant="body1" color="error">
        This field is required.
      </Typography>
    );
  }
  return null;
};
