import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import {
  zCatalogOwnerResponse,
  zCatalogOwnerResponseT,
} from '@briljera/common';
import { FieldValidation } from '@rjsf/utils';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { CatalogOwnerField, CatalogOwnerFieldLocal } from './CatalogOwnerField';

export const CatalogOwnerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component:
      process.env.NODE_ENV === 'development'
        ? CatalogOwnerFieldLocal // Use CatalogOwnerField in production
        : CatalogOwnerField, // Use CatalogOwnerFieldLocal in development
    name: 'CatalogComponentOwner',
    validation: (
      value: zCatalogOwnerResponseT,
      validation: FieldValidation,
    ) => {
      try {
        zCatalogOwnerResponse.parse(value);
      } catch (e) {
        validation.addError(JSON.stringify(value));
      }
    },
  }),
);
