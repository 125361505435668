type Datapoint = [key: string, value: string];

function sum(datapoints: Datapoint[][]): number {
  const total = datapoints
    .reduce((acc: Datapoint[], val: Datapoint[]) => acc.concat(val))
    .map((point: Datapoint) => Number(point[1]))
    .reduce((acc, v) => acc + v, 0);

  return total;
}

function average(datapoints: Datapoint[][]): number {
  const avg = datapoints
    .reduce((acc: Datapoint[], val: Datapoint[]) => acc.concat(val))
    .map((point: Datapoint) => Number(point[1]))
    .reduce((acc, v, _i, a) => acc + v / a.length, 0);

  return avg;
}

// Fetch JS Errors Data from SignalFx
export const fetchJsErrorsData = async (
  appName: string,
  startTimestamp: number,
  endTimestamp: number,
  backendUrl: string,
) => {
  try {
    const response = await fetch(
      `${backendUrl}/api/proxy/splunk-api/v1/timeserieswindow?query=sf_metric%3Arum.client_error.count%20AND%20app%3A${appName}%20AND%20sf_environment%3A*prod&startMS=${startTimestamp}&endMS=${endTimestamp}&resolution=300000`,
    );

    if (!response.ok) {
      if (response.status === 404) {
        throw new Error('404 Not Found');
      }
      throw new Error('Network response was not ok');
    }
    const matrix = await response.json();
    const result = sum(Object.values(matrix?.data) as Datapoint[][]);

    return result;
  } catch (error) {
    throw new Error(`${error}`);
  }
};

// Fetch Network Requests Data from SignalFx
export const fetchNetworkRequestsData = async (
  appName: string,
  startTimestamp: number,
  endTimestamp: number,
  backendUrl: string,
) => {
  try {
    const response = await fetch(
      `${backendUrl}/api/proxy/splunk-api/v1/timeserieswindow?query=sf_metric%3Arum.resource_request.count%20AND%20app%3A${appName}%20AND%20sf_environment%3Aprod&startMS=${startTimestamp}&endMS=${endTimestamp}&resolution=300000`,
    );

    if (!response.ok) {
      if (response.status === 404) {
        throw new Error('404 Not Found');
      }
      throw new Error('Network response was not ok');
    }
    const matrix = await response.json();
    const result = sum(Object.values(matrix?.data) as Datapoint[][]);

    return result;
  } catch (error) {
    throw new Error(`${error}`);
  }
};

// Fetch Backend Request Latency Data from SignalFx
export const fetchBackendLatency = async (
  appName: string,
  startTimestamp: number,
  endTimestamp: number,
  backendUrl: string,
) => {
  try {
    const response = await fetch(
      `${backendUrl}/api/proxy/splunk-api/v1/timeserieswindow?query=sf_metric%3Arum.resource_request.ttfb.time.ns.p75%20AND%20app%3A${appName}%20AND%20sf_environment%3Aprod&startMS=${startTimestamp}&endMS=${endTimestamp}&resolution=300000`,
    );

    if (!response.ok) {
      if (response.status === 404) {
        throw new Error('404 Not Found');
      }
      throw new Error('Network response was not ok');
    }
    const matrix = await response.json();
    const result = average(Object.values(matrix?.data) as Datapoint[][]) / 1e9;

    return parseFloat(result.toFixed(1));
  } catch (error) {
    throw new Error(`${error}`);
  }
};
