import { OwnerFieldOutputT } from '@briljera/common';

export const TO_BE_CREATED_OBJECT_ID = 'TO_BE_CREATED_OBJECT_ID';

export const mkOwnerFieldOutput = (teamName: string): OwnerFieldOutputT => ({
  owner: teamName,
  admin: {
    securityGroupObjectId: TO_BE_CREATED_OBJECT_ID,
    securityGroupName: `${teamName}-Admin-PLAT-SG`,
    expectedGithubTeamName: `${teamName}-Admins`,
  },
  contrib: {
    securityGroupObjectId: TO_BE_CREATED_OBJECT_ID,
    securityGroupName: `${teamName}-Contrib-PLAT-SG`,
    expectedGithubTeamName: `${teamName}-Contributors`,
  },
  read: {
    securityGroupObjectId: TO_BE_CREATED_OBJECT_ID,
    securityGroupName: `${teamName}-Read-PLAT-SG`,
    expectedGithubTeamName: `${teamName}-Readers`,
  },
});
