import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from '@backstage/core-components';
import Text from '@ingka/text';
import GitHubIcon from '@mui/icons-material/GitHub';

import Tooltip from '@ingka/tooltip';

import '@ingka/svg-icon/dist/style.css';
import '@ingka/button/dist/style.css';
import '@ingka/tooltip/dist/style.css';
import '@ingka/focus/dist/style.css';
import '@ingka/text/dist/style.css';

const useStyles = makeStyles({
  prsContainers: {
    height: '190px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '3px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '5px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
  },
  prItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '4px 8px',

    '&:nth-child(odd)': {
      backgroundColor: '#FFFFFF',
      border: '0.5px solid #F3F3F3',
      borderRadius: '4px',
    },
    '&:nth-child(even)': {
      backgroundColor: '#FFFFFF00',
    },
  },
  flex: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  prTitle: {
    width: '150px',
  },
  repoName: {
    padding: '4px 8px',
    borderRadius: '4px',
    backgroundColor: '#EEEEEE',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  prStatus: {
    '&.approved': {
      color: '#0E8900',
    },
    '&.review-required': {
      color: '#0061CF',
    },
  },
});

interface PullRequest {
  title: string;
  url: string;
  repository: {
    name: string;
    url: string;
  };
  number: number;
  status: string;
  createdAt: string;
}

interface PRCardProps {
  pullRequests: PullRequest[];
}

const truncateTitle = (title: string, length: number): string => {
  return title.length > length ? `${title.substring(0, length)}...` : title;
};

const PRCard: React.FC<PRCardProps> = ({ pullRequests }) => {
  const classes = useStyles();

  return (
    <div className={classes.prsContainers}>
      {pullRequests.map(pr => (
        <div key={`${pr.repository}-${pr.number}`} className={classes.prItem}>
          <div className={classes.flex}>
            <Link to={pr.url} className={classes.prTitle}>
              <Tooltip
                children={
                  <>
                    <Text tagName="span" bodySize="s">
                      <strong> #{pr.number}</strong>{' '}
                      {truncateTitle(pr.title, 18)}
                    </Text>
                  </>
                }
                position="trailing"
                tooltipText={pr.title}
              />
            </Link>
            <Link to={pr.repository.url}>
              <Tooltip
                children={
                  <>
                    <Text
                      tagName="span"
                      bodySize="s"
                      className={classes.repoName}
                    >
                      <GitHubIcon fontSize="small" />
                      {truncateTitle(pr.repository.name, 10)}
                    </Text>
                  </>
                }
                position="leading"
                tooltipText={pr.repository.name}
              />
            </Link>
          </div>
          <Text
            className={`${classes.prStatus} ${pr.status
              .toLowerCase()
              .replace(' ', '-')}`}
            tagName="span"
            bodySize="s"
          >
            <strong>{pr.status}</strong>
          </Text>
        </div>
      ))}
    </div>
  );
};

export default PRCard;
