import { createApiRef } from '@backstage/core-plugin-api';

export const maintainersApiRef = createApiRef<MaintainersApi>({
  id: 'plugin.maintainers.service',
});

export interface SecurityGroup {
  name: string;
  description: string;
  ownerEmailIds: string[];
  memberEmailIds: string[];
}

export interface User {
  name: string;
  email: string;
}

export interface MaintainersApi {
  getToken(): Promise<string | undefined>;
  decryptToken(token: string): Promise<string | undefined>;
  createSG(securityGroup: SecurityGroup): Promise<string | undefined>;
  getEmails(startsWith: string): Promise<User[] | undefined>;
}
