import { TechRadarLoaderResponse, TechRadarApi } from './api';
import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';

export class IkeaTechRadarApi implements TechRadarApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;
  constructor(options: {
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  async load(): Promise<TechRadarLoaderResponse> {
    const url = `${await this.discoveryApi.getBaseUrl('tech-radar')}/radar`;
    const { token: idToken } = await this.identityApi.getCredentials();

    const data = await fetch(url, {
      method: 'GET',
      headers: {
        ...(idToken && { Authorization: `Bearer ${idToken}` }),
      },
    }).then(res => res.json());

    return {
      ...data,
      entries: data.entries.map(
        (entry: { timeline: Array<{ date: string | number | Date }> }) => ({
          ...entry,
          timeline: entry.timeline.map(
            (timeline: { date: string | number | Date }) => ({
              ...timeline,
              date: new Date(timeline.date),
            }),
          ),
        }),
      ),
    };
  }
}
