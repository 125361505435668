import { z } from 'zod';

const extractFrontendConfigFromPackageJsonsAndConfigDTS = () => {
  const NON_EMPTY_STRING = z.string().min(1);
  return z
    .object({
      'dpz.channelUrl': NON_EMPTY_STRING,
      'dpz.docUrl': NON_EMPTY_STRING,
      'apim.swaggerDocUrl': NON_EMPTY_STRING,
      'apim.apiCatalogUrl': NON_EMPTY_STRING,
      'apim.archAreaUrl': NON_EMPTY_STRING,
      'apim.appRegUrl': NON_EMPTY_STRING,
      'apim.channelUrl': NON_EMPTY_STRING,
      'apim.oauthUrl': NON_EMPTY_STRING,
      'apim.endpointUrl': NON_EMPTY_STRING,
      'apim.githubUrl': NON_EMPTY_STRING,
      'app.baseUrl': NON_EMPTY_STRING,
      'app.deploymentEnvironment': NON_EMPTY_STRING,
      'app.analytics.splunk.realm': NON_EMPTY_STRING,
      'app.analytics.splunk.deploymentEnvironment': NON_EMPTY_STRING,
      'app.analytics.splunk.rumAccessToken': NON_EMPTY_STRING,
      'app.analytics.splunk.applicationName': NON_EMPTY_STRING,
      'app.analytics.splunk.version': NON_EMPTY_STRING,
      'backend.baseUrl': NON_EMPTY_STRING,
    })
    .strict();
};

export const zFrontendConfig =
  extractFrontendConfigFromPackageJsonsAndConfigDTS();

export type FrontendConfig = z.infer<typeof zFrontendConfig>;

export const frontendConfigKeys = Object.keys(zFrontendConfig.shape);
