import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'awscore',
});

export const accountRouteRef = createSubRouteRef({
  id: 'awscore:account-page',
  path: '/accounts/:accountId',
  parent: rootRouteRef,
});
export const teamRouteRef = createSubRouteRef({
  id: 'awscore:team-page',
  path: '/teams/:teamName',
  parent: rootRouteRef,
});
