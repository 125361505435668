export interface RepoDetails {
  owner: string;
  repo: string;
}

interface Review {
  state: string;
}

export interface PullRequest {
  title: string;
  url: string;
  repository: {
    name: string;
    url: string;
  };
  number: number;
  status: string;
  createdAt: string;
  state: string;
  reviews: {
    nodes: Review[];
  };
}

const toCamelCase = (str: string): string =>
  str
    .replace(/[-_\s]+(.)?/g, (_, chr) => (chr ? chr.toUpperCase() : ''))
    .replace(/^[A-Z]/, match => match.toLowerCase());

const generateQuery = (repos: RepoDetails[]): string => {
  const queryParts = repos.map(repo => {
    const alias = toCamelCase(repo.repo);
    return `
      ${alias}: repository(owner: "${repo.owner}", name: "${repo.repo}") {
        pullRequests(last: 10, states: OPEN) {
          nodes {
            title
            url
            number
            state
            createdAt
            repository {
              name
              url
            }
            reviews(first: 1) {
              nodes {
                state
              }
            }
          }
        }
      }
    `;
  });

  return `{${queryParts.join('\n')}}`;
};

export const fetchPullRequests = async (
  repos: RepoDetails[],
  token: string,
): Promise<PullRequest[]> => {
  const query = generateQuery(repos);
  const url = 'https://api.github.com/graphql';
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };

  const response = await fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify({ query }),
  });

  if (!response.ok) {
    throw new Error(`Failed to fetch data: ${response.statusText}`);
  }

  const responseData = await response.json();

  if (responseData.errors) {
    throw new Error(`GraphQL errors: ${JSON.stringify(responseData.errors)}`);
  }

  if (!responseData.data) {
    throw new Error('Invalid response format: Missing data');
  }

  const prData = Object.keys(responseData.data).flatMap(repoAlias =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    responseData.data[repoAlias].pullRequests.nodes
      .filter(
        (pr: PullRequest) =>
          pr.state !== 'CLOSED' &&
          pr.reviews.nodes.every(
            (review: Review) => review.state !== 'APPROVED',
          ),
      )
      .map((pr: PullRequest) => ({
        title: pr.title,
        url: pr.url,
        repository: { name: pr.repository.name, url: pr.repository.url },
        number: pr.number,
        status:
          pr.reviews.nodes.length > 0
            ? pr.reviews.nodes[0].state
            : 'Review Required',
        createdAt: pr.createdAt,
      })),
  );

  prData.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  );

  return prData;
};
