import { z } from 'zod';

const zSecurityGroup = z.object({
  securityGroupObjectId: z.string(),
  securityGroupName: z.string(),
  expectedGithubTeamName: z.string(),
});
export const zOwnerObject = z.object({
  owner: z.string(),
  admin: zSecurityGroup,
  contrib: zSecurityGroup,
  read: zSecurityGroup,
  guest: z.optional(zSecurityGroup),
});
export const zSasgAPIResponse = z.array(zOwnerObject);
export type OwnerFieldOutputT = z.infer<typeof zOwnerObject>;
export type SasgAPIResponseT = z.infer<typeof zSasgAPIResponse>;
export const zCatalogOwnerArray = z.array(z.string());
export type CatalogOwnerFieldOutputT = z.infer<typeof zCatalogOwnerArray>;
export const zCatalogOwnerResponse = z.string();
export type zCatalogOwnerResponseT = z.infer<typeof zCatalogOwnerResponse>;

export const toOwnerFieldOutput = (
  sasgResponse: SasgAPIResponseT,
  owner: unknown,
): OwnerFieldOutputT =>
  zOwnerObject.parse(
    sasgResponse.find(ownerObject => ownerObject.owner === owner),
  );
export const toCatalogOwnerFieldOutput = (
  rolesResponse: CatalogOwnerFieldOutputT | undefined,
  owner: unknown,
): string => {
  if (!rolesResponse) {
    return '';
  }
  const matchingRoles: string | undefined =
    rolesResponse.find(str => str === owner) || 'String not found';
  return matchingRoles;
};

const TO_BE_CREATED_OBJECT_ID = 'TO_BE_CREATED_OBJECT_ID';
export const needToCreateSecurityGroup = (
  ownerFieldOutput: OwnerFieldOutputT,
): boolean =>
  ownerFieldOutput.admin.securityGroupObjectId === TO_BE_CREATED_OBJECT_ID;

export const mkOwnerFieldOutput = (teamName: string): OwnerFieldOutputT => ({
  owner: teamName,
  admin: {
    securityGroupObjectId: TO_BE_CREATED_OBJECT_ID,
    securityGroupName: `${teamName}-Admin-PLAT-SG`,
    expectedGithubTeamName: `${teamName} Admin`,
  },
  contrib: {
    securityGroupObjectId: TO_BE_CREATED_OBJECT_ID,
    securityGroupName: `${teamName}-Contrib-PLAT-SG`,
    expectedGithubTeamName: `${teamName} Contributers`,
  },
  read: {
    securityGroupObjectId: TO_BE_CREATED_OBJECT_ID,
    securityGroupName: `${teamName}-Read-PLAT-SG`,
    expectedGithubTeamName: `${teamName} READ`,
  },
});
const zDeploymentEnvironment = z.union([
  z.literal('qa'),
  z.literal('dev'),
  z.literal('prod'),
  z.literal('ppe'),
  z.literal('localDev'),
]);

export type DeploymentEnvironment = z.infer<typeof zDeploymentEnvironment>;

export const parseDeployedEnvironment = (deploymentEnvironmentStr: string) =>
  z
    .union([zDeploymentEnvironment, z.literal('unknown')])
    .parse(deploymentEnvironmentStr);

export const zTeamNameValidationResult = z.object({
  result: z.union([
    z.literal('VALID_TEAM_NAME'),
    z.literal('INVALID_TEAM_NAME'),
  ]),
});

export type TeamNameValidationResult = z.infer<
  typeof zTeamNameValidationResult
>;

export const zErrorResponseBody = z.object({
  error: z.object({ message: z.string(), name: z.string() }),
  response: z.object({ statusCode: z.number() }),
});
