import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useApi } from '@backstage/core-plugin-api';
import Text from '@ingka/text';
import Loading, { LoadingBall } from '@ingka/loading';
import {
  getEntityRelations,
  catalogApiRef,
} from '@backstage/plugin-catalog-react';
import {
  CompoundEntityRef,
  GroupEntity,
  RELATION_OWNER_OF,
  stringifyEntityRef,
} from '@backstage/catalog-model';

import '@ingka/loading/dist/style.css';
import '@ingka/text/dist/style.css';

import { SplunkDocsModal } from './SplunkDocsModal';

import { BackendLatency, JsErrorsBar, NetworkRequestsBar } from './InfoBars';
import { useFrontendConfig } from '@internal/common-frontend';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    borderRadius: '3px',
    border: '0.5px solid #E8E8E8',
    padding: theme.spacing(1.5, 3),
    paddingBottom: theme.spacing(3),
    backgroundColor: '#F8F8F8',
    height: '300px',
  },
  mSmall: {
    margin: theme.spacing(0, 0),
  },
  infoBarContainer: {
    height: '170px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '3px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '5px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
  },
  splunkHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}));

export const SplunkCard = ({
  groupEntities,
}: {
  groupEntities: GroupEntity[];
}) => {
  const classes = useStyles();
  const configApi = useFrontendConfig();
  const catalogApi = useApi(catalogApiRef);
  const [loading, setLoading] = useState(true);
  const [splunkApps, setSplunkApps] = useState<string[]>([]);
  const backendUrl = configApi.getString('backend.baseUrl');

  useEffect(() => {
    const fetchComponents = async () => {
      if (groupEntities.length === 0) {
        setLoading(false);
        return;
      }

      const groupArray: string[] = [];
      for (const item of groupEntities) {
        const entityRefs = getEntityRelations(
          item,
          RELATION_OWNER_OF,
        ) as CompoundEntityRef[];
        groupArray.push(...entityRefs.map(e => stringifyEntityRef(e)));
      }

      // Fetch components by Refs
      const comps = (
        await catalogApi.getEntitiesByRefs({
          entityRefs: groupArray,
        })
      ).items.filter(g => g !== undefined) as GroupEntity[];

      const filteredEntities = comps
        .filter(entity =>
          entity?.metadata?.annotations?.hasOwnProperty(
            'backstage.io/splunk-app',
          ),
        )
        .map(
          entity => entity?.metadata?.annotations?.['backstage.io/splunk-app'],
        );

      setSplunkApps(filteredEntities as string[]);
      setLoading(false);
    };

    fetchComponents();
  }, [catalogApi, groupEntities]);

  const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
  const endTimestamp = Date.now();
  const startTimestamp = endTimestamp - oneWeekInMilliseconds; // start time is 7 days before current time

  const prevStartTimestamp = startTimestamp - oneWeekInMilliseconds;
  const prevEndTimestamp = startTimestamp;

  if (loading) {
    return (
      <div className={classes.card}>
        <div className={classes.splunkHeader}>
          <Text tagName="h1" bodySize="l">
            Splunk Observability
          </Text>
          <SplunkDocsModal />
        </div>
        <Text tagName="p" bodySize="s" className={classes.mSmall}>
          Insights of incidents and metrics of your components for last 7 days
          in Splunk Observability Cloud.
        </Text>
        <br />
        <Loading>
          <LoadingBall />
        </Loading>
      </div>
    );
  }

  if (splunkApps.length === 0) {
    return (
      <div className={classes.card}>
        <div className={classes.splunkHeader}>
          <Text tagName="h1" bodySize="l">
            Splunk Observability
          </Text>
          <SplunkDocsModal />
        </div>
        <Text tagName="p" bodySize="s" className={classes.mSmall}>
          Insights of incidents and metrics of your components for last 7 days
          in Splunk Observability Cloud.
        </Text>
        <br />
        <Text tagName="p" bodySize="s">
          You do not have any components with Splunk Observability, you can find
          documentation to add your components to Splunk Observability by
          clicking info icon.
        </Text>
      </div>
    );
  }

  return (
    <div className={classes.card}>
      <div className={classes.splunkHeader}>
        <Text tagName="h1" bodySize="l">
          Splunk Observability
        </Text>
        <SplunkDocsModal />
      </div>
      <Text tagName="p" bodySize="s" className={classes.mSmall}>
        Quick Insights of incidents and metrics and comparison with previous 7
        days of your components in Splunk Observability Cloud.
      </Text>
      <br />
      <div className={classes.infoBarContainer}>
        {splunkApps.map(app => (
          <React.Fragment key={app}>
            <Text tagName="h6" bodySize="s" className={classes.mSmall}>
              Application: {app}
            </Text>
            <JsErrorsBar
              backendUrl={backendUrl}
              startTimestamp={startTimestamp}
              endTimestamp={endTimestamp}
              appName={app}
              prevStartTimestamp={prevStartTimestamp}
              prevEndTimestamp={prevEndTimestamp}
            />
            <NetworkRequestsBar
              backendUrl={backendUrl}
              startTimestamp={startTimestamp}
              endTimestamp={endTimestamp}
              appName={app}
              prevStartTimestamp={prevStartTimestamp}
              prevEndTimestamp={prevEndTimestamp}
            />
            <BackendLatency
              backendUrl={backendUrl}
              startTimestamp={startTimestamp}
              endTimestamp={endTimestamp}
              appName={app}
              prevStartTimestamp={prevStartTimestamp}
              prevEndTimestamp={prevEndTimestamp}
            />
            <br />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
