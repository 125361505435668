import {
  createTheme,
  createUnifiedThemeFromV4,
  createBaseThemeOptions,
  defaultTypography,
  genPageTheme,
  shapes,
  UnifiedTheme,
} from '@backstage/theme';
import { yellow } from '@material-ui/core/colors';
import { BackstageOverrides } from '@backstage/core-components';

export const lightTheme = createTheme({
  ...createBaseThemeOptions({
    typography: {
      ...defaultTypography,
      fontFamily: 'Noto IKEA',
    },
    palette: {
      type: 'light',
      background: {
        default: '#FFF',
      },
      status: {
        ok: '#0A8A00',
        warning: '#FFA524',
        error: '#E00751',
        running: '#2E77D0',
        pending: '#FFDB00',
        aborted: '#929292',
      },
      bursts: {
        fontColor: '#FEFEFE',
        slackChannelText: '#DFDFDF',
        backgroundColor: {
          default: '#7C3699',
        },
        gradient: {
          linear: 'linear-gradient(-137deg, #4BB8A5 0%, #187656 100%)',
        },
      },
      primary: {
        main: '#000',
      },
      banner: {
        info: '#0058A3',
        error: '#E00751',
        text: '#FFFFFF',
        link: '#111111',
      },
      border: '#DFDFDF',
      textContrast: '#111111',
      textVerySubtle: '#DFDFDF',
      textSubtle: '#767676',
      highlight: '#FFCD3F',
      errorBackground: '#FFFBCC',
      warningBackground: '#F59B23',
      infoBackground: '#ebf5ff',
      errorText: '#E00751',
      infoText: '#0058A3',
      warningText: '#111111',
      linkHover: 'gray',
      link: '#000',
      gold: yellow.A700,
      navigation: {
        background: '#FFF',
        indicator: '#FFDB00',
        color: '#000',
        selectedColor: '#000',
        linkHover: '#000',
        navItem: {
          hoverBackground: '#d3d3d3',
        },
      },
      pinSidebarButton: {
        icon: '#111111',
        background: '#929292',
      },
      tabbar: {
        indicator: '#FFDB00',
      },
    },
  }),
  defaultPageTheme: 'home',
  pageTheme: {
    home: genPageTheme({ colors: ['#000', '#000'], shape: shapes.wave }),
    documentation: genPageTheme({
      colors: ['#000', '#000'],
      shape: shapes.wave2,
    }),
    tool: genPageTheme({ colors: ['#000', '#000'], shape: shapes.round }),
    service: genPageTheme({
      colors: ['#000', '#000'],
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: ['#000', '#000'],
      shape: shapes.wave,
    }),
    library: genPageTheme({
      colors: ['#000', '#000'],
      shape: shapes.wave,
    }),
    other: genPageTheme({ colors: ['#000', '#000'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#000', '#000'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#000', '#000'], shape: shapes.wave2 }),
  },
});

export const darkTheme = createTheme({
  ...createBaseThemeOptions({
    typography: {
      ...defaultTypography,
      fontFamily: 'Noto IKEA',
    },
    palette: {
      type: 'dark',
      background: {
        default: '#333333',
      },
      status: {
        ok: '#71CF88',
        warning: '#FFB84D',
        error: '#F84C55',
        running: '#3488E3',
        pending: '#FEF071',
        aborted: '#9E9E9E',
      },
      bursts: {
        fontColor: '#FEFEFE',
        slackChannelText: '#ddd',
        backgroundColor: {
          default: '#7C3699',
        },
        gradient: {
          linear: 'linear-gradient(-137deg, #4BB8A5 0%, #187656 100%)',
        },
      },
      primary: {
        main: '#9CC9FF',
        dark: '#82BAFD',
      },
      secondary: {
        main: '#FF88B2',
      },
      banner: {
        info: '#2E77D0',
        error: '#E22134',
        text: '#FFFFFF',
        link: '#000000',
        warning: '#FF9800',
      },
      border: '#E6E6E6',
      textContrast: '#FFFFFF',
      textVerySubtle: '#727272',
      textSubtle: '#CCCCCC',
      highlight: '#FFFBCC',
      errorBackground: '#FFEBEE',
      warningBackground: '#F59B23',
      infoBackground: '#ebf5ff',
      errorText: '#CA001B',
      infoText: '#004e8a',
      warningText: '#000000',
      linkHover: '#82BAFD',
      link: '#9CC9FF',
      gold: yellow.A700,
      navigation: {
        background: '#FFF',
        indicator: '#FFF',
        color: '#FFF',
        selectedColor: '#FFF',
        navItem: {
          hoverBackground: '#FFF',
        },
        submenu: {
          background: '#FFF',
        },
      },
      pinSidebarButton: {
        icon: '#404040',
        background: '#BDBDBD',
      },
      tabbar: {
        indicator: '#9BF0E1',
      },
    },
  }),
  defaultPageTheme: 'home',
});

// Custom Overrides
export const createCustomThemeOverrides = (): BackstageOverrides => {
  return {
    BackstageSidebar: {
      drawer: {
        boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)',
      },
    },
    BackstageSidebarItem: {
      root: {
        borderRadius: '1px',
        textDecoration: 'none',
        boxShadow: 'inset 5 5 5 5px rgba(0, 0, 0, 0.1)',
      },
    },
    BackstageHeader: {
      header: {
        backgroundImage: 'none',
        background: '#000',
        color: yellow.A700,
      },
    },
    MuiTable: {
      root: {
        backgroundColor: '#F5F5F5',
      },
    },
    MuiButton: {
      root: {
        borderRadius: '100px',
        backgroundColor: 'black',
        color: 'white',
        marginRight: '10px',
        minWidth: '180px',
        minHeight: '45px',
        '&:hover': {
          backgroundColor: 'black',
          textDecoration: 'none',
        },
        textTransform: 'none',
        textPrimary: 'none',
        '&:disabled': {
          color: 'white',
          backgroundColor: 'darkgrey',
          padding: '10px 50px 10px 50px',
          marginRight: '10px',
        },
        '&:text': {
          padding: '10px 50px 10px 50px',
        },
      },
      outlinedPrimary: {
        '&:hover': {
          backgroundColor: '#000',
          textDecoration: 'none',
        },
        color: 'white',
        // Add any other styles you want to override for this specific label
      },
      textPrimary: {
        color: 'white',
        padding: '10px 40px 10px 40px',
        '&:hover': {
          textDecoration: 'none',
          backgroundColor: '#000',
        },
      },
      containedPrimary: {
        backgroundColor: 'black',
        color: 'white',
        '&:hover': {
          backgroundColor: 'black',
          textDecoration: 'none',
        },
        textTransform: 'none',
        padding: '10px 30px 10px 30px',
        fontSize: '1rem',
      },
    },

    MuiLink: {
      root: {
        textDecoration: 'underline',
      },
    },
  };
};

export const overrideLightTheme: UnifiedTheme = createUnifiedThemeFromV4({
  ...lightTheme,
  overrides: {
    ...lightTheme.overrides,
    ...createCustomThemeOverrides(),
  },
});
