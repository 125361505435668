/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';

import { Header, HeaderLabel, Page } from '@backstage/core-components';

/**
 * Props for {@link TechDocsPageWrapper}
 *
 * @public
 */
export type TechDocsPageWrapperProps = {
  children?: React.ReactNode;
};

/**
 * Component wrapping a techdocs page with Page and Header components
 *
 * @public
 */
export const TechDocsPageWrapper = (props: TechDocsPageWrapperProps) => {
  const { children } = props;

  return (
    <Page themeId="service">
      <Header
        style={{ height: '12vh' }}
        title="Documentation"
        subtitle="Documentation available in INTER IKEA"
      >
        <a href="/catalog/default/group/dp.swd-dpz.team">
          {' '}
          <HeaderLabel label="Owner" value="dp.swd-dpz.team" />
        </a>
        <HeaderLabel label="Lifecycle" value="Production" />
      </Header>
      {children}
    </Page>
  );
};
