import { useEffect } from 'react';

import {
  discoveryApiRef,
  fetchApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { FrontendConfig, zFrontendConfig } from '@briljera/common';

const frontendConfigStore: FrontendConfig = {
  'app.analytics.splunk.realm': 'dummyRealm',
} as FrontendConfig;

export const useFrontendConfig = () => {
  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);
  useEffect(() => {
    discoveryApi.getBaseUrl('dpz-apis').then(dpzBaseUrl =>
      fetchApi
        .fetch(`${dpzBaseUrl}/frontend-config`)
        .then(response => response.json())
        .then(zFrontendConfig.parse)
        .then(receivedFrontendConfig => {
          Object.assign(frontendConfigStore, receivedFrontendConfig);
        }),
    );
  }, [discoveryApi, fetchApi]);

  const getString = (_configPath: keyof FrontendConfig) =>
    frontendConfigStore[_configPath];

  return {
    getString,
    getOptionalString: getString,
  };
};

export const LoadFrontendConfig = () => {
  useFrontendConfig();
  return null;
};

export const testExport = {
  getFrontendConfig: () => frontendConfigStore,
};
