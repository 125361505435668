import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useAsync } from 'react-use';
import Text from '@ingka/text';
import Loading, { LoadingBall } from '@ingka/loading';
import informativeIcon from '@ingka/ssr-icon/paths/information-circle';
import Tooltip from '@ingka/tooltip';
import SSRIcon from '@ingka/ssr-icon';
import arrowUp from '@ingka/ssr-icon/paths/chevron-up-small';
import arrowDown from '@ingka/ssr-icon/paths/chevron-down-small';

import '@ingka/loading/dist/style.css';
import '@ingka/text/dist/style.css';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/button/dist/style.css';
import '@ingka/tooltip/dist/style.css';
import '@ingka/focus/dist/style.css';

import {
  fetchBackendLatency,
  fetchJsErrorsData,
  fetchNetworkRequestsData,
} from './Splunk-Functions/SplunkFetchData';

const useStyles = makeStyles((theme: Theme) => ({
  dataHeader: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  flexSpan: {
    display: 'flex',
    alignItems: 'center',
  },
  metricsContainer: {
    borderRadius: '3px',
    border: '0.5px solid #E8E8E8',
    padding: theme.spacing(1, 2),
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    height: '46px',
  },
  tooltip: {
    '& button': {
      background: 'transparent',
    },
    '& button:hover': {
      background: 'transparent',
    },
  },
  jsInfoContainer: {
    background: 'linear-gradient(90deg, #FFDEDE 0%, #FFFEFE 100%)',
  },
  networkInfoContainer: {
    background:
      'linear-gradient(90deg, #FFEEDE 0%, rgba(255, 238, 222, 0.25) 100%)',
  },
  latencyInfoContainer: {
    background:
      'linear-gradient(90deg, #EBEBEB 0%, rgba(235, 235, 235, 0.25) 100%)',
  },
}));

interface MetricInfoProps {
  title: string;
  infoBarColor: string;
  fillColor: string;
  dataLoading: boolean;
  dataError: Error | undefined;
  data: number | undefined;
  percentageChange: number | null;
  metricType: 'jsErrors' | 'networkRequests' | 'backendLatency';
}

const MetricInfo: React.FC<MetricInfoProps> = ({
  title,
  infoBarColor,
  fillColor,
  dataLoading,
  dataError,
  data,
  percentageChange,
  metricType,
}) => {
  const classes = useStyles();

  function formatNumber(num: number) {
    if (num < 1000) {
      return num.toString();
    } else if (num < 1000000) {
      return `${(num / 1000).toFixed(1)}K`;
    }
    return `${(num / 1000000).toFixed(1)}M`;
  }

  const renderData = (): JSX.Element => {
    if (dataLoading) {
      return (
        <>
          <Loading>
            <LoadingBall size="medium" />
          </Loading>
        </>
      );
    } else if (dataError && !dataLoading) {
      return (
        <>
          <Tooltip
            ssrIcon={informativeIcon}
            className={classes.tooltip}
            tooltipText={`${dataError.message}`}
          />
        </>
      );
    }
    return <>{formatNumber(data || 0)}</>;
  };

  const renderPercentageChange = (changeAmount: number): JSX.Element => {
    if (changeAmount === 0) {
      return (
        <span style={{ marginLeft: '5px' }}>
          {Math.abs(changeAmount).toFixed(1)}%
        </span>
      );
    }

    // Lower Percentage Change is positive for JS errors and Backend Latency
    const isPositiveChange = ['jsErrors', 'backendLatency'].includes(metricType)
      ? changeAmount < 0
      : changeAmount > 0;

    return (
      <span style={{ color: isPositiveChange ? 'green' : 'red' }}>
        <SSRIcon paths={changeAmount > 0 ? arrowUp : arrowDown} />
        {Math.abs(changeAmount).toFixed(1)}%
      </span>
    );
  };

  return (
    <div className={`${classes.metricsContainer} ${infoBarColor}`}>
      <div className={classes.dataHeader}>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="9"
            viewBox="0 0 8 9"
            fill="none"
          >
            <circle cx="4" cy="4.3999" r="4" fill={fillColor} />
          </svg>
          <Text tagName="span" bodySize="s">
            {title}
          </Text>
        </div>
        <Text tagName="span" className={classes.flexSpan} bodySize="s">
          <strong>{renderData()}</strong>
          {percentageChange !== null &&
            renderPercentageChange(percentageChange)}
        </Text>
      </div>
    </div>
  );
};

interface MetricBarProps {
  title: string;
  infoBarColor: string;
  fillColor: string;
  fetchData: (
    appName: string,
    startTimestamp: number,
    endTimestamp: number,
    backendUrl: string,
  ) => Promise<number>;
  appName: string;
  backendUrl: string;
  startTimestamp: number;
  endTimestamp: number;
  prevStartTimestamp: number;
  prevEndTimestamp: number;
  metricType: 'jsErrors' | 'networkRequests' | 'backendLatency';
}

const MetricBar: React.FC<MetricBarProps> = ({
  title,
  infoBarColor,
  fillColor,
  fetchData,
  appName,
  backendUrl,
  startTimestamp,
  endTimestamp,
  prevStartTimestamp,
  prevEndTimestamp,
  metricType,
}) => {
  const {
    value: currentData,
    loading: currentLoading,
    error: currentError,
  } = useAsync(
    () => fetchData(appName, startTimestamp, endTimestamp, backendUrl),
    [appName, startTimestamp, endTimestamp],
  );

  const {
    value: prevData,
    loading: prevLoading,
    error: prevError,
  } = useAsync(
    () => fetchData(appName, prevStartTimestamp, prevEndTimestamp, backendUrl),
    [appName, prevStartTimestamp, prevEndTimestamp],
  );

  if (
    currentError &&
    (currentError?.message.includes('404 Not Found') || // Skip if metrics doesn't exist on rum application, 404 Not Found
      prevError?.message.includes('404 Not Found'))
  ) {
    return null;
  }

  const percentageChange =
    prevData && currentData
      ? ((Number(currentData) - Number(prevData)) / Number(prevData)) * 100
      : null;

  return (
    <MetricInfo
      title={title}
      infoBarColor={infoBarColor}
      fillColor={fillColor}
      dataLoading={currentLoading || prevLoading}
      dataError={currentError || prevError}
      data={currentData}
      percentageChange={percentageChange}
      metricType={metricType}
    />
  );
};

interface MetricComponentProps {
  appName: string;
  backendUrl: string;
  startTimestamp: number;
  endTimestamp: number;
  prevStartTimestamp: number;
  prevEndTimestamp: number;
}

export const JsErrorsBar: React.FC<MetricComponentProps> = props => (
  <MetricBar
    title="JS errors"
    infoBarColor={useStyles().jsInfoContainer}
    fillColor="#FF0000"
    fetchData={fetchJsErrorsData}
    metricType="jsErrors"
    {...props}
  />
);

export const NetworkRequestsBar: React.FC<MetricComponentProps> = props => (
  <MetricBar
    title="Network requests"
    infoBarColor={useStyles().networkInfoContainer}
    fillColor="#FFA500"
    fetchData={fetchNetworkRequestsData}
    metricType="networkRequests"
    {...props}
  />
);

export const BackendLatency: React.FC<MetricComponentProps> = props => (
  <MetricBar
    title="Backend latency"
    infoBarColor={useStyles().latencyInfoContainer}
    fillColor="#808080"
    fetchData={fetchBackendLatency}
    metricType="backendLatency"
    {...props}
  />
);
