import { createApiRef } from '@backstage/core-plugin-api';
import { Team, Account } from '@internal/plugin-awscore-common';

/**
 * The API used by the awscore plugin to list and read teams/accounts.
 *
 * @public
 */
export interface AwscoreApi {
  /** Lists the accounts. */
  listAccounts(): Promise<Response>;

  /** Reads the contents of the account. */
  getAccount(id: string): Promise<Response>;

  /** Create account. */
  createAccount(account: Account): Promise<Response>;

  /** Update account */
  updateAccount(account: Account): Promise<Response>;

  /** Delete account */
  deleteAccount(id: string): Promise<Response>;

  /** Lists the teams. */
  listTeams(): Promise<Response>;

  /** Reads the contents of the team. */
  getTeam(id: string): Promise<Response>;

  /** Create team. */
  createTeam(team: Team): Promise<Response>;

  /** Update team */
  updateTeam(team: Team): Promise<Response>;

  /** Delete team */
  deleteTeam(id: string): Promise<Response>;

  /** Get the profile information of the logged-in user */
  getIdentity(): Promise<string>;
}

/**
 * ApiRef for the AwscoreApi.
 *
 * @public
 */
export const awscoreApiRef = createApiRef<AwscoreApi>({
  id: 'plugin.awscore.api',
});
