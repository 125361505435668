import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import Button from '@ingka/button';
import * as React from 'react';
import { useToggle } from 'react-use';
import { OwnerFieldOutputT } from '@briljera/common';

const AccessGroupRow = (props: {
  groupName: string;
  fieldValues: Map<string, string>;
}) => (
  <TableRow key={props.groupName}>
    <TableCell component="th" scope="row">
      <Typography variant="h6" style={{ textTransform: 'capitalize' }}>
        {props.groupName}
      </Typography>
    </TableCell>
    <TableCell align="left">
      {[...props.fieldValues.keys()].map(k => (
        <Typography key={k} variant="body2">
          {k}
        </Typography>
      ))}
    </TableCell>
    <TableCell align="left">
      {[...props.fieldValues.values()].map(k => (
        <Typography key={k} variant="body2">
          {k}
        </Typography>
      ))}
    </TableCell>
  </TableRow>
);

const mkMap = <V,>(o: Record<string, V>): Map<string, string> => {
  const m = new Map();
  Object.entries(o).forEach(([k, v]) => m.set(k, v));
  return m;
};

const omit = <K extends string, V, O extends Record<K, V>>(
  o: O,
  k: K,
): Omit<O, K> => {
  const clone = { ...o };
  delete clone[k];
  return clone;
};

const toEntries = <T,>(o: Record<string, T>): Array<[string, T]> =>
  Object.entries(o);

export const ToggleableOwnerObjectTable = (props: {
  ownerObject: OwnerFieldOutputT;
}) => {
  const [isVisible, toggle] = useToggle(false);
  return (
    <Box paddingY={2}>
      <Button onClick={toggle} small type="secondary">
        {isVisible ? 'Hide details' : 'Show Details'}
      </Button>
      {isVisible && (
        <Table aria-label="Owner object details">
          <TableHead>
            <TableRow>
              <TableCell>Access Group</TableCell>
              <TableCell align="left">Fields</TableCell>
              <TableCell align="left">Values</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {toEntries(omit(props.ownerObject, 'owner')).map(
              ([ag, fieldValues]) => (
                <AccessGroupRow
                  groupName={ag}
                  fieldValues={mkMap(fieldValues)}
                  key={ag}
                />
              ),
            )}
          </TableBody>
        </Table>
      )}
    </Box>
  );
};
