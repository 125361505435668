import * as React from 'react';
import { Header, Progress } from '@backstage/core-components';
import { makeStyles, Grid } from '@material-ui/core';
import {
  ErrorApiError,
  errorApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import { GroupEntity } from '@backstage/catalog-model';
import { OwnerShipCard } from './ownershipComponents/OwnerShipCard';
import Text from '@ingka/text';
import { Link } from '@backstage/core-components';
import { SplunkCard } from '@internal/backstage-plugin-splunk-oc';
import { DocsCard } from './ownershipComponents/DocsCard';

import '@ingka/text/dist/style.css';
import { PRWidget } from './prWidget/PRWidget';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(3),
  },
  customMargin: {
    margin: theme.spacing(1, 0),
  },
}));

export const HomePage = () => {
  const classes = useStyles();
  const identityApi = useApi(identityApiRef);
  const catalogApi = useApi(catalogApiRef);
  const errorApi = useApi(errorApiRef);
  const [loading, setLoading] = React.useState(true);
  const [userGroups, setUserGroups] = React.useState<GroupEntity[]>([]);

  // Fetch user groups
  React.useEffect(() => {
    const fetchUserGroups = async () => {
      try {
        const response = await identityApi.getBackstageIdentity();
        if (response.ownershipEntityRefs.length === 0) {
          setLoading(false);
          return;
        }

        // Fetch group Entities
        const groupEntities = (
          await catalogApi.getEntitiesByRefs({
            entityRefs: response.ownershipEntityRefs,
          })
        ).items.filter(g => g !== undefined) as GroupEntity[];

        setUserGroups(groupEntities);
        setLoading(false);
      } catch (error) {
        errorApi.post(error as ErrorApiError);
      }
    };

    fetchUserGroups();
  }, [catalogApi, errorApi, identityApi, setUserGroups]);

  if (loading) {
    return (
      <>
        <Text tagName="h2" headingSize="m" className={classes.customMargin}>
          <Progress />
        </Text>
      </>
    );
  }

  return (
    <>
      <Header title="Samspela Dashboard" />
      <div className={classes.container}>
        <Text tagName="h2" headingSize="m" className={classes.customMargin}>
          Team ownership
        </Text>
        <Text tagName="p" bodySize="m">
          Here you can see all the components, APIs, and TechDocs owned by your
          teams.
        </Text>
        {userGroups.length === 0 && (
          <Text tagName="p" bodySize="m">
            You are not part of any team, to create/join team please visit this{' '}
            <Link to="/samspela-info" underline="always">
              page
            </Link>
          </Text>
        )}
        <br />
        <Grid container>
          <Grid item md={3}>
            <OwnerShipCard
              groupEntities={userGroups}
              kind="Component"
              desc="Click on view more to explore all the components owned by your teams."
            />
          </Grid>
          <Grid item md={3}>
            <OwnerShipCard
              groupEntities={userGroups}
              kind="API"
              desc="Click on view more to explore all the APIs owned by your teams."
            />
          </Grid>
          <Grid item md={3}>
            <DocsCard groupEntities={userGroups} />
          </Grid>
        </Grid>
        <br />
        <br />
        <Text tagName="h2" headingSize="m" className={classes.customMargin}>
          Team Insights
        </Text>
        <Text tagName="p" bodySize="m">
          Here you can see all the tech insights for your teams.
        </Text>
        <Grid container>
          <Grid item md={4}>
            <SplunkCard groupEntities={userGroups} />
          </Grid>
          <Grid item md={4}>
            <PRWidget groupEntities={userGroups} />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
