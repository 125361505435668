import * as crypto from 'crypto';
import jwt from 'jsonwebtoken';

const ALGORITHM_NAME = 'aes-128-gcm';
const ALGORITHM_NONCE_SIZE = 12;
const ALGORITHM_TAG_SIZE = 16;

export function encryptAndEncode(idToken: string, key: Buffer): string {
  const salt = crypto.randomBytes(ALGORITHM_NONCE_SIZE);
  const ciphertextAndNonceAndSalt = Buffer.concat([
    salt,
    encrypt(Buffer.from(idToken, 'utf8'), key),
  ]);
  return ciphertextAndNonceAndSalt.toString('base64');
}
export function decodeAndDecrypt(
  base64CiphertextAndNonceAndSalt: string,
  key: Buffer,
): string {
  const ciphertextAndNonceAndSalt = Buffer.from(
    base64CiphertextAndNonceAndSalt,
    'base64',
  );

  ciphertextAndNonceAndSalt.slice(0, ALGORITHM_NONCE_SIZE);
  const ciphertextAndNonce =
    ciphertextAndNonceAndSalt.slice(ALGORITHM_NONCE_SIZE);
  return decrypt(ciphertextAndNonce, key).toString('utf8');
}

function encrypt(plaintext: Buffer, key: Buffer): Buffer {
  const nonce = crypto.randomBytes(ALGORITHM_NONCE_SIZE);
  const cipher = crypto.createCipheriv(ALGORITHM_NAME, key, nonce);
  const ciphertext = Buffer.concat([cipher.update(plaintext), cipher.final()]);
  return Buffer.concat([nonce, ciphertext, cipher.getAuthTag()]);
}

function decrypt(ciphertextAndNonce: Buffer, key: Buffer): Buffer {
  const nonce = ciphertextAndNonce.slice(0, ALGORITHM_NONCE_SIZE);
  const ciphertext = ciphertextAndNonce.slice(
    ALGORITHM_NONCE_SIZE,
    ciphertextAndNonce.length - ALGORITHM_TAG_SIZE,
  );
  const tag = ciphertextAndNonce.slice(
    ciphertext.length + ALGORITHM_NONCE_SIZE,
  );
  const cipher = crypto.createDecipheriv(ALGORITHM_NAME, key, nonce);
  cipher.setAuthTag(tag);
  return Buffer.concat([cipher.update(ciphertext), cipher.final()]);
}

export async function decodeToken(token: string) {
  try {
    const decodedToken = jwt.decode(token);
    return decodedToken;
  } catch (error) {
    return null;
  }
}
