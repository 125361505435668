import { createPermission } from '@backstage/plugin-permission-common';

export const maintainersAccessPermissionRoles = [
  'group:default/internal.maintainers.integration',
  'group:default/internal.maintainers-admin.integration',
];

export const hasMaintainersClaims = (claims: string[]) => {
  return maintainersAccessPermissionRoles.some(requiredClaim =>
    claims.includes(requiredClaim),
  );
};

/**
 * Permission definition.
 *
 * @public
 */
export const maintainersAccessPermission = createPermission({
  name: 'maintainers.access',
  attributes: { action: 'read' },
});

/**
 * List of all maintainers permissions.
 *
 * @public
 */
export const maintainersPermissions = [maintainersAccessPermission];
