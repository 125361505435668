import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import {
  catalogApiRef,
  getEntityRelations,
} from '@backstage/plugin-catalog-react';
import {
  CompoundEntityRef,
  Entity,
  GroupEntity,
  RELATION_OWNER_OF,
  stringifyEntityRef,
} from '@backstage/catalog-model';

import { Mod } from './Modal';
import Text from '@ingka/text';
import { Link } from '@backstage/core-components';

import '@ingka/text/dist/style.css';
import '@ingka/hyperlink/dist/style.css';
import { useApi } from '@backstage/core-plugin-api';
import { getCompoundEntityRef } from '@backstage/catalog-model';

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: 'black',
    padding: theme.spacing(2.5),
    height: '180px',
    '& h1': {
      color: 'white',
    },
    '& p': {
      color: '#c4c4c4',
    },
  },
  mSmall: {
    margin: theme.spacing(1, 0),
  },
}));

interface GroupsComponents {
  groupName: string;
  components: CompoundEntityRef[];
}

interface OwnershipCardProps {
  groupEntities: GroupEntity[];
}

export const DocsCard: React.FC<OwnershipCardProps> = ({ groupEntities }) => {
  const classes = useStyles();
  const catalogApi = useApi(catalogApiRef);
  const [loading, setLoading] = React.useState(true);
  const [componentCount, setComponentCount] = React.useState<number>(0);
  const [groupComponents, setGroupComponents] = React.useState<
    GroupsComponents[]
  >([]);

  React.useEffect(() => {
    const fetchComponents = async () => {
      if (!groupEntities.length) {
        setLoading(false);
        return;
      }

      const groupPromises = groupEntities.map(async item => {
        const entityRefs = getEntityRelations(item, RELATION_OWNER_OF, {
          kind: 'Component',
        }).map(stringifyEntityRef);

        // Fetch and filter components with TechDocs annotations
        const { items: completeEntities } = await catalogApi.getEntitiesByRefs({
          entityRefs,
        });

        const DocsComponents = completeEntities.filter(
          (entity): entity is Entity =>
            !!entity?.metadata.annotations?.['backstage.io/techdocs-ref'],
        );

        const componentRefs = DocsComponents.map(entity =>
          getCompoundEntityRef(entity),
        );

        return {
          groupName: item.metadata.name,
          components: componentRefs,
        };
      });

      const groupArray = await Promise.all(groupPromises);

      setGroupComponents(groupArray);
      setComponentCount(
        groupArray.reduce((sum, group) => sum + group.components.length, 0),
      );
      setLoading(false);
    };

    fetchComponents();
  }, [catalogApi, groupEntities]);

  if (loading) {
    return (
      <>
        <div className={classes.card}>
          <Text tagName="h1" bodySize="l" className={classes.mSmall}>
            Loading
          </Text>
        </div>
      </>
    );
  }

  if (componentCount === 0) {
    return (
      <div className={classes.card}>
        <Text tagName="h1" bodySize="l" className={classes.mSmall}>
          No TechDocs
        </Text>
        <Text tagName="p" bodySize="s" className={classes.mSmall}>
          Your teams have no TechDocs!
          <br />
          you can follow this{' '}
          <Link
            color="inherit"
            underline="always"
            to="/samspela-info/techdocs-onboarding"
          >
            Link
          </Link>{' '}
          to learn how to create.
        </Text>
      </div>
    );
  }

  return (
    <div className={classes.card}>
      <Text tagName="h1" bodySize="l" className={classes.mSmall}>
        {componentCount} TechDocs
      </Text>
      <Text tagName="p" bodySize="s" className={classes.mSmall}>
        Click on view more to explore all the TechDocs owned by your teams.
      </Text>
      <br />
      <hr />
      <Mod groupComponents={groupComponents} kind="TechDocs" doc />
    </div>
  );
};
