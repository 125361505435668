import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { awscoreApiRef, AwscoreClient } from './api';

import { rootRouteRef } from './routes';

export const awscorePlugin = createPlugin({
  id: 'awscore',
  apis: [
    createApiFactory({
      api: awscoreApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
        identityApi: identityApiRef,
      },
      factory({ discoveryApi, fetchApi, identityApi }) {
        return new AwscoreClient({
          discoveryApi,
          fetchApi,
          identityApi,
        });
      },
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const AwscorePage = awscorePlugin.provide(
  createRoutableExtension({
    name: 'AwscoreRoot',
    component: () =>
      import('./components/AwscoreRoot').then(m => m.AwscoreRoot),
    mountPoint: rootRouteRef,
  }),
);
