import { BackstageTheme } from '@backstage/theme';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSupportConfig } from '@backstage/core-components';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { usePermission } from '@backstage/plugin-permission-react';
import Text from '@ingka/text';
import Hyperlink from '@ingka/hyperlink';
import '@ingka/hyperlink/dist/style.css';

interface IErrorPageProps {
  status: string;
  statusMessage: string;
  additionalInfo?: React.ReactNode;
  supportUrl?: string;
}

/** @public */
export type ErrorPageClassKey = 'container' | 'title' | 'subtitle';

const useStyles = makeStyles<BackstageTheme>(
  theme => ({
    container: {
      padding: theme.spacing(8),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2),
      },
    },
    title: {
      paddingBottom: theme.spacing(5),
      [theme.breakpoints.down('xs')]: {
        paddingBottom: theme.spacing(4),
        fontSize: theme.typography.h3.fontSize,
      },
    },
    subtitle: {
      color: theme.palette.textSubtle,
    },
  }),
  { name: 'BackstageErrorPage' },
);

/**
 * Error page with status and description
 *
 * @public
 *
 */
export function BriljeraErrorPage(props: IErrorPageProps) {
  const { status, statusMessage, additionalInfo, supportUrl } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const support = useSupportConfig();

  const { allowed: canCreatePermission } = usePermission({
    permission: catalogEntityCreatePermission,
  });

  return (
    <Grid container spacing={0} className={classes.container}>
      <Grid item xs={12} sm={8} md={4}>
        <Text
          headingSize="m"
          tagName="h1"
          data-testid="error"
          className={classes.subtitle}
        >
          ERROR {status}: {statusMessage}
        </Text>
        <Text headingSize="m" tagName="h1" className={classes.subtitle}>
          {additionalInfo}
        </Text>
        <Text headingSize="m" tagName="h1" className={classes.title}>
          Looks like something went wrong.
        </Text>
        {!canCreatePermission && (
          <Text bodySize="l">
            You don't have permissions to create components. You need to have a
            Samspela team and and be onboarded into Github Inter Digital
            Organization. please visit{' '}
            <Hyperlink url="/samspela-info">Get Access</Hyperlink>.
          </Text>
        )}
        <Text headingSize="m" tagName="h1">
          <Hyperlink
            url="#"
            data-testid="go-back-link"
            onClick={() => navigate(-1)}
          >
            Go back
          </Hyperlink>
          ... or please{' '}
          <Hyperlink url={supportUrl || support.url}>contact support</Hyperlink>{' '}
          if you think this is a bug.
        </Text>
      </Grid>
    </Grid>
  );
}
