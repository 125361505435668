import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { MaintainersApi, SecurityGroup, User } from './MaintainersApi';

export class MaintainersClient implements MaintainersApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  public constructor(options: {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }
  public async createSG(
    securityGroup: SecurityGroup,
  ): Promise<string | undefined> {
    const baseUrl = await this.discoveryApi.getBaseUrl('dpz-apis');
    // eslint-disable-next-line no-console
    console.log(`calling backend at ${baseUrl}`);
    return this.callApi<string>({
      url: baseUrl,
      path: 'create-sg',
      method: 'POST',
      body: JSON.stringify({
        nameOfSecurityGroup: securityGroup.name,
        description: securityGroup.description,
        ownerEmailIds: securityGroup.ownerEmailIds,
        memberEmailIds: securityGroup.memberEmailIds,
      }),
    });
  }

  public async getToken(): Promise<string | undefined> {
    const baseUrl = await this.discoveryApi.getBaseUrl('dpz-apis');
    return this.callApi<string>({
      url: baseUrl,
      path: 'get-token',
      method: 'GET',
    });
  }

  public async decryptToken(token: string): Promise<string | undefined> {
    const baseUrl = await this.discoveryApi.getBaseUrl('dpz-apis');
    return this.callApi<string>({
      url: baseUrl,
      path: 'decrypt-token',
      method: 'POST',
      body: JSON.stringify({ token }),
    });
  }

  public async getEmails(startsWith: string): Promise<User[] | undefined> {
    const baseUrl = await this.discoveryApi.getBaseUrl('dpz-apis');
    return this.callApi<User[]>({
      url: baseUrl,
      path: `users?startsWith=${startsWith}`,
      method: 'GET',
    });
  }

  private async callApi<T>(options: {
    url: string;
    path: string;
    method: string;
    query?: { [key in string]: string };
    body?: string;
  }): Promise<T | undefined> {
    const response = await this.fetchApi.fetch(
      `${options.url}/${options.path}`,
      {
        method: options.method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: options.body,
      },
    );
    if (response.status === 200) {
      return (await response.json()) as T;
    }

    return undefined;
  }
}
