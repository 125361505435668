import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const getAccessPlugin = createPlugin({
  id: 'get-access',
  routes: {
    root: rootRouteRef,
  },
});

export const GetAccessPage = getAccessPlugin.provide(
  createRoutableExtension({
    name: 'GetAccessPage',
    component: () => import('./components').then(m => m.GetAccess),
    mountPoint: rootRouteRef,
  }),
);
