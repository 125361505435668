import { Page, Header, HeaderLabel } from '@backstage/core-components';
import React from 'react';
import { ApiCatalog } from './ApiCatalog';

export const ApiCatalogPage = () => (
  <Page themeId="service">
    <Header
      style={{ height: '12vh' }}
      title="API"
      subtitle="INTER IKEA API EXPLORER"
    >
      <a href="/catalog/default/group/dp.apim.team">
        {' '}
        <HeaderLabel label="Owner" value="dp.apim.team" />
      </a>
      <HeaderLabel label="Lifecycle" value="Production" />
    </Header>
    <ApiCatalog />
  </Page>
);
