import {
  Content,
  ContentHeader,
  CreateButton,
  SupportButton,
  Header,
  HeaderLabel,
  Page,
} from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';
import {
  catalogPlugin,
  CatalogKindHeader,
  CatalogTable,
  DefaultCatalogPageProps,
} from '@backstage/plugin-catalog';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import {
  EntityListProvider,
  CatalogFilterLayout,
  EntityTypePicker,
  UserListPicker,
  EntityTagPicker,
} from '@backstage/plugin-catalog-react';
import { usePermission } from '@backstage/plugin-permission-react';
import * as React from 'react';
import { builtVersion } from '../../builtVersionPlaceholder';

const version = `${builtVersion.branch}/${builtVersion.date}/${builtVersion.tag}`;

export const CustomizedCatalogPage = ({
  columns,
  actions,
  initiallySelectedFilter = 'owned',
}: DefaultCatalogPageProps) => {
  const createComponentLink = useRouteRef(
    catalogPlugin.externalRoutes.createComponent,
  );
  const { allowed: canCreatePermission } = usePermission({
    permission: catalogEntityCreatePermission,
  });
  return (
    <Page themeId="catalog">
      <Header title="INTER IKEA Catalog">
        <a href="/catalog/default/group/dp.swd-dpz.team">
          <HeaderLabel label="Owner" value="dp.swd-dpz.team" />
        </a>
        <HeaderLabel label="Lifecycle" value="Production" />
        <HeaderLabel label="Version" value={version} />
      </Header>
      <EntityListProvider pagination>
        <Content>
          <ContentHeader titleComponent={<CatalogKindHeader />}>
            {createComponentLink &&
              canCreatePermission &&
              process.env.NODE_ENV === 'production' && (
                <CreateButton title="Create" to={createComponentLink()} />
              )}
            {process.env.NODE_ENV === 'development' && (
              <CreateButton
                title="Create"
                to={createComponentLink && createComponentLink()}
              />
            )}
            <SupportButton>All your software catalog entities</SupportButton>
          </ContentHeader>
          <CatalogFilterLayout>
            <CatalogFilterLayout.Filters>
              <EntityTypePicker />
              <UserListPicker initialFilter={initiallySelectedFilter} />
              <EntityTagPicker />
            </CatalogFilterLayout.Filters>
            <CatalogFilterLayout.Content>
              <CatalogTable columns={columns} actions={actions} />
            </CatalogFilterLayout.Content>
          </CatalogFilterLayout>
        </Content>
      </EntityListProvider>
    </Page>
  );
};
