import * as React from 'react';
import {
  CodeSnippet,
  Content,
  ContentHeader,
  Header,
  InfoCard,
  Page,
  SupportButton,
} from '@backstage/core-components';

import Text from '@ingka/text';
import '@ingka/text/dist/style.css';
import '@ingka/button/dist/style.css';
import '@ingka/modal/dist/style.css';

import Hyperlink from '@ingka/hyperlink';

import '@ingka/focus/dist/style.css';
import '@ingka/hyperlink/dist/style.css';
import { Grid } from '@material-ui/core';
import { ImportStepper } from '@backstage/plugin-catalog-import';

export const catalogInfoYaml = `apiVersion: backstage.io/v1alpha1
kind: Component
metadata:
  namespace: organization name #inter-ikea-digital for example, lowercase
  name: Your repo name #my-awesome-repo
  title: Your repo name in Samspela #Display name in catalog
  description: Description of service #Optional
spec:
  type: Type of service #Documentation, service, Website, etc.
  lifecycle: production
  owner: owner team in samepela
`;

export const ServiceRegistrationGuide = () => {
  return (
    <>
      <Page themeId="home">
        <Header title="Register an existing component" />
        <Content>
          <ContentHeader title="Start tracking your component in SAMSPELA">
            <SupportButton>
              Start tracking your component in SAMSPELA by adding it to the
              software catalog.
            </SupportButton>
          </ContentHeader>

          <Grid container spacing={2} direction="row-reverse">
            <Grid item xs={12} md={4} lg={6} xl={8}>
              <InfoCard title="Register an existing component">
                <Text tagName="h2" headingSize="s">
                  1. If you do not have a team, request{' '}
                  <Hyperlink
                    className="link link--black"
                    url="/samspela-info/requestTeam"
                  >
                    onboarding to SAMSPELA.
                  </Hyperlink>
                </Text>
                <Text tagName="h2" headingSize="s">
                  2. Once onboarded{' '}
                  <Hyperlink className="link link--black" url="/catalog-import">
                    import your service
                  </Hyperlink>{' '}
                  using the catalog-info.yaml file structure below. More
                  information about catalog-info.yaml can be found on{' '}
                  <Hyperlink
                    className="link link--black"
                    url="https://backstage.io/docs/features/software-catalog/descriptor-format#overall-shape-of-an-entity"
                  >
                    Backstage.
                  </Hyperlink>
                </Text>
                <CodeSnippet
                  language="yaml"
                  text={catalogInfoYaml}
                  showCopyCodeButton
                />

                <Text tagName="h2" headingSize="s">
                  3. Verify your services in{' '}
                  <Hyperlink
                    className="link link--black"
                    url="/catalog?filters%5Bkind%5D=component&filters%5Buser%5D=owned"
                    target="_blank"
                  >
                    Software Catalog.
                  </Hyperlink>
                </Text>
              </InfoCard>
            </Grid>

            <Grid item xs={12} md={8} lg={6} xl={4}>
              <ImportStepper />
            </Grid>
          </Grid>
        </Content>
      </Page>
    </>
  );
};
